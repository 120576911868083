import { SearchRounded } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";

export default function Search( { handleSearch }: { handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void }) {
  return (
    <TextField
      placeholder="Search"
      type="text"
      onChange={handleSearch}
      variant="outlined"
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRounded />
          </InputAdornment>
        ),
      }}
    />
  );
}
