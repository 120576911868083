export const globalColors = {
    tableHeadBg: "#cae4fe",
    backgroundColor: "#e7f3ff",
    headerColor : "#5AE1EC",
    buttonBg: "#02A0FC",
    statBadge: "#A6EDFA",
    statText: "#000000"
}   

export const pieColors = [
  "#DC391B",
  "#033766",
  "#0356A1",
  "#3DBCC9",
  "#ED9F30",
  "#9085AE",
  "#9085AE",
];
