import { Rectangle } from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { PieModel } from "../../Models/Statistics.model";
import { pieColors } from "../../globalColors";
import { LightTooltip } from "../LightTooltip/LightTooltip";

export const Pie = ({ data }: { data: PieModel[] }) => {
  const filtered = data
    .filter((item) => item.label?.length > 2)
    .filter((item) => Number(item.value) > 0)
    .sort((a, b) => {
      if (Array.isArray(a.value) && Array.isArray(b.value)) {
        return a.value.join(", ").localeCompare(b.value.join(", "));
      } else {
        return Number(b.value) - Number(a.value);
      }
    });
  filtered.forEach((item) => {
    if (Array.isArray(item.label)) item.label = item.label.sort().join(", ");
    if (Array.isArray(item.value)) item.value = item.value.sort().join(", ");
  });

  const formatted = filtered.map((item, index) => {
    return {
      id: item.id,
      label: item.label,
      value: Number(item.value),
      color: pieColors[index % pieColors.length],
    };
  });

  const sum = formatted.reduce((a, b) => a + b.value, 0);

  const CustomTooltip = (props: any) => {
    const index = props.itemData.dataIndex ?? 0;
    const percent = Math.round((props.series.data[index].value / sum) * 100);
    const angle =
      props.series.data[index].endAngle - props.series.data[index].startAngle;
    if (angle < 0.4)
      return (
        <Box margin={1}>
          <Chip
            sx={{
              bgcolor: "#ffffff97",
              position: "relative",
              top: -40,
            }}
            label={percent + "%"}
            variant="outlined"
          />
        </Box>
      );
    else return <></>;
  };

  return (
    <>
      {data.length > 0 ? (
        <Box mx={1} display="flex">
          <List
            sx={{
              overflowY: "auto",
              height: "min-content",
              boxSizing: "border-box",
              maxHeight: "290px",
              direction: "rtl",
              overflowX: "hidden",
              width: "159px",
            }}
          >
            {console.log(filtered)}
            {formatted.map((item) => {
              return (
                <ListItem sx={{ padding: 0 }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <Rectangle
                      sx={{ color: item.color, transform: "scale(1,0.5)" }}
                    />
                  </ListItemIcon>
                  <LightTooltip title={item.label+"("+item.value+")"} placement="top">
                    <ListItemText
                      sx={{
                        textWrap: "nowrap",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      primary={item.label+"("+item.value+")"}
                      disableTypography
                    />
                  </LightTooltip>
                </ListItem>
              );
            })}
          </List>
          <PieChart
            colors={pieColors}
            series={[
              {
                data: formatted,
                innerRadius: 70,
                outerRadius: 120,
                arcLabel: (item) => Math.floor((item.value / sum) * 100) + "%",
                arcLabelMinAngle: 15,
              },
            ]}
            width={300}
            height={300}
            margin={{
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
              },
            }}
            slots={{
              itemContent: CustomTooltip,
            }}
            slotProps={{
              legend: {
                hidden: true,
                classes: {
                  root: "MuiChartsLegend-column",
                  mark: "",
                  series: "",
                },
                direction: "column",
                position: { vertical: "middle", horizontal: "left" },
                padding: 0,
                itemMarkWidth: 20,
                itemMarkHeight: 8,
                markGap: 5,
                itemGap: 9,
              },
            }}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height={"80%"}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
