import { LocalStorageService } from "./../LocalStorage.service";
import { AuthTokens, AuthUser } from "../../Models";
import { RequestEnum, Routes, StorageKeys } from "../../commons/enums";
import GlobalApiService from "./GlobalApi.service";
import { ErrorResponse } from "../../commons/interfaces";
import axios from "axios";
import { environment } from "../../environments/Environment";

export class AuthService {
    private static instance: AuthService;
    private localStorageService = new LocalStorageService();
    static getInstance() {
        if (!this.instance) {
            this.instance = new AuthService();
        }
        return this.instance;
    }
    async login(authUser: AuthUser): Promise<boolean> {
        const loginResponse: AuthTokens | ErrorResponse | string =
            await GlobalApiService.generalRequest<AuthTokens>(
                RequestEnum.Post,
                Routes.postLogin,
                authUser
            );
        if (
            typeof loginResponse === "object" &&
            "OTPToken" in loginResponse
        ) {
            this.localStorageService.setToken(
                StorageKeys.AccessToken,
                loginResponse.OTPToken
            );
            return true;
            // TODO: exception handling
        } else if (typeof loginResponse === "string") {
            console.error(loginResponse);
            return false;

        } else if (typeof loginResponse === "object") {
            console.error(loginResponse.message);
            return false;
        }
        return false;
    }
   
    async handleOTP(code: String): Promise<boolean> {
        const OTPResponse: AuthTokens | ErrorResponse | string =
            await GlobalApiService.generalRequest<AuthTokens>(
                RequestEnum.Post,
                Routes.postOTP,
                {code}
            );
        if (
            typeof OTPResponse === "object" &&
            "accessToken" in OTPResponse
        ) {
            this.localStorageService.setToken(
                StorageKeys.AccessToken,
                OTPResponse.accessToken
            );
            this.localStorageService.setToken(
                StorageKeys.RefreshToken,
                OTPResponse.refreshToken
                );
            return true;
            // TODO: exception handling
        } else if (typeof OTPResponse === "string") {
            console.error(OTPResponse);
            return false;

        } else if (typeof OTPResponse === "object") {
            console.error(OTPResponse.message);
            return false;
        }
        return false;
    }

    async logout() {
        const response = await GlobalApiService.generalRequest<string>(
            RequestEnum.Post,
            Routes.POST_LOGOUT,
            {}
        );
        this.localStorageService.removeToken(StorageKeys.AccessToken);
        this.localStorageService.removeToken(StorageKeys.RefreshToken);
    }

    async forgotPassword(email: string) {
        await GlobalApiService.generalRequest<string>(
            RequestEnum.Post,
            Routes.postForgotPassword,
            { email }
        );
        //TODO: handle response
    }
    
    async resendOTP() {
        await GlobalApiService.generalRequest<string>(
            RequestEnum.Get,
            Routes.postResendOtp,
            {}
        );
    }

    public getByHeaderToken(
        tokenType: StorageKeys
    ): { ["Authorization"]: string } | {} {
        const token = this.localStorageService.getToken(tokenType);
        return token ? { Authorization: `${RequestEnum.Bearer} ${token}` } : {};
    }
}
