import { MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { Immigrant } from "../../Models/Immigrant.model";
import { ImmigrantsTableHeader } from "../../commons/I18n";
import { Routes } from "../../commons/enums";
import { ImmigrantsTable } from "../../commons/enums/GlobalTableHeaders.enum";
import { GlobalTitles } from "../../commons/enums/Titles.enum";
import {
  ImmigrantResponse,
  ImmigrantsTableType,
} from "../../commons/types/ImmigrantsTypes";
import { globalColors } from "../../globalColors";
import { Service } from "../../services/service.service";
import { Export } from "../Export/Export";
import Filter from "../Filter/Filter";
import Search from "../Search/Search";
import "../Style/style.scss";
import { CreateImmigrants } from "./CreateImmigrants/CreateImmigrants";
import { CreatMultiImmigrants } from "./CreateMultiImmigrants/CreateMultiImmigrants";
import { LightTooltip } from "../LightTooltip/LightTooltip";

export const Immigrants = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [immigrants, setImmigrants] = useState([] as Immigrant[]);
  const [total, setTotalImmigrants] = useState(0);
  const [search, setSearch] = useState("");

  // Table header
  const headCells: readonly ImmigrantsTableType[] = [
    {
      id: ImmigrantsTable.FullName,
      numeric: true,
      disablePadding: false,
      label: ImmigrantsTableHeader.FullName,
    },
    {
      id: ImmigrantsTable.Email,
      numeric: true,
      disablePadding: false,
      label: ImmigrantsTableHeader.Email,
    },
    {
      id: ImmigrantsTable.Phone,
      numeric: true,
      disablePadding: false,
      label: ImmigrantsTableHeader.Phone,
    },
    {
      id: ImmigrantsTable.Country,
      numeric: true,
      disablePadding: false,
      label: ImmigrantsTableHeader.Country,
    },
    {
      id: ImmigrantsTable.Language,
      numeric: true,
      disablePadding: false,
      label: ImmigrantsTableHeader.Language,
    },
    {
      id: ImmigrantsTable.RegistrationDate,
      numeric: true,
      disablePadding: false,
      label: ImmigrantsTableHeader.RegistrationDate,
    },
    {
      id: ImmigrantsTable.RouteType,
      numeric: true,
      disablePadding: false,
      label: ImmigrantsTableHeader.RouteType,
    },
    {
      id: ImmigrantsTable.Professional,
      numeric: true,
      disablePadding: false,
      label: ImmigrantsTableHeader.Professional,
    },
    {
      id: ImmigrantsTable.MentorName,
      numeric: true,
      disablePadding: false,
      label: ImmigrantsTableHeader.MentorName,
    },
    {
      id: ImmigrantsTable.MentorPhone,
      numeric: true,
      disablePadding: false,
      label: ImmigrantsTableHeader.MentorPhone,
    },
  ];

  useEffect(() => {
    getimmigrants();
  }, [page, limit]);

  const getimmigrants = async () => {
    const result: ImmigrantResponse = await Service.getInstance().getImmigrants(
      page + 1,
      limit
    );
    setImmigrants(result.immigrants);
    setTotalImmigrants(result.total);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const data = {
    nodes: immigrants.filter((item) => {
      return (
        item?.name?.toString().toLowerCase().indexOf(search.toLowerCase()) >
          -1 ||
        Object(item?.user)
          ?.email?.toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1 ||
        item?.escortRoute
          ?.toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1
      );
    }),
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isEvenOrOdd = (index: number) => {
    return index % 2 !== 0;
  };

  return (
    <>
      <div className="immigrants-wrapper">
        <Box>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              sx={{ tableLayout: "fixed" }}

            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={10} padding="none">
                    <Typography
                      textAlign={"left"}
                      variant="h5"
                      my={3}
                      className="page-title"
                    >
                      {GlobalTitles.Immigrants}
                    </Typography>

                    <Box
                      display={"flex"}
                      gap={1}
                      my={2}
                      flex={"1 0 50%"}
                      alignItems={"center"}
                    >
                      <Search handleSearch={handleSearch} />
                      <Filter setSearch={setSearch}>
                        <MenuItem value="">{"All"}</MenuItem>
                        <MenuItem value="professional">
                          {"Professional"}
                        </MenuItem>
                        <MenuItem value="family">{"Family"}</MenuItem>
                        <MenuItem value="social">{"Social"}</MenuItem>
                      </Filter>
                      <Box
                        flexBasis={"80%"}
                        display={"flex"}
                        justifyContent={"end"}
                      >
                        <Export exportRequest={Routes.getImmigrants} />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ bgcolor: globalColors.tableHeadBg }}>
                  {headCells.map((headCell) => (
                    <LightTooltip title={headCell.label} placement="top">
                      <TableCell
                        key={headCell.id}
                        align={"center"}
                        padding={"normal"}
                        sortDirection={false}
                        sx={{fontWeight: "bold"}}
                      >
                        {headCell.label}
                      </TableCell>
                    </LightTooltip>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data["nodes"].map((row, index) => {
                  const tableRowStyle = "researcher-row ";
                  const tableRowBlueStyle = "researcher-row table-row-bg-blue";
                  const displayRow = (
                    <TableRow
                      className={
                        isEvenOrOdd(index) ? tableRowBlueStyle : tableRowStyle
                      }
                    >
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">
                        {Object(row.user).email}
                      </TableCell>
                      <TableCell align="center">
                        {Object(row.user).phoneNumber}
                      </TableCell>
                      <TableCell align="center">
                        {row.originCountry ? row.originCountry + " " : " "}
                      </TableCell>
                      <TableCell align="center">
                        {row.languages ? row.languages + " " : " "}
                      </TableCell>
                      <TableCell align="center">
                        {new Date(
                          Object(row.user)?.registrationDate
                        ).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="center">
                        {row.escortRoute ? row.escortRoute + " " : ""}
                      </TableCell>
                      <TableCell align="center">
                        {Object.values(row.professionalField).join(" ")}
                      </TableCell>
                      <TableCell align="center">
                        {Object(row.mentor)?.name}
                      </TableCell>
                      <TableCell align="center">
                        {Object(row.mentor)?.user?.phoneNumber}
                      </TableCell>
                    </TableRow>
                  );
                  return displayRow;
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            alignContent={"center"}
            flex={"1 0 30%"}
          >
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <CreateImmigrants></CreateImmigrants>
              <CreatMultiImmigrants></CreatMultiImmigrants>
            </Box>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              className="pagination"
            />
          </Box>
        </Box>
      </div>
    </>
  );
};
