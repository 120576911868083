import { Delete } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import { InquiriesModel } from "../../Models/Inquiries.model";
import { InquriesTableHeader } from "../../commons/I18n";
import { Routes } from "../../commons/enums";
import { InquriesTable } from "../../commons/enums/GlobalTableHeaders.enum";
import { GlobalTitles } from "../../commons/enums/Titles.enum";
import {
  InquiriesResponse,
  InquiriesTableType,
} from "../../commons/types/InquiriesTypes";
import { globalColors } from "../../globalColors";
import { ConfirmBox } from "../../model/ConfirmBox";
import { Service } from "../../services/service.service";
import { Export } from "../Export/Export";
import { LightTooltip } from "../LightTooltip/LightTooltip";

export const Inquiries = () => {
  const [page, setPage] = useState(0);
  const [limit] = useState(10);
  const [total, setTotalInquiries] = useState(0);
  const [inquiries, setInquiries] = useState([] as InquiriesModel[]);
  const [date, setDate] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setID] = useState("");
  const [header, setHeader] = useState("");
  const { RangePicker } = DatePicker;

  // Table header
  const headCells: readonly InquiriesTableType[] = [
    {
      id: InquriesTable.Header,
      numeric: true,
      disablePadding: false,
      label: InquriesTableHeader.Header,
    },
    {
      id: InquriesTable.Email,
      numeric: true,
      disablePadding: false,
      label: InquriesTableHeader.Email,
    },
    {
      id: InquriesTable.Content,
      numeric: true,
      disablePadding: false,
      label: InquriesTableHeader.Content,
    },
    {
      id: InquriesTable.Date,
      numeric: true,
      disablePadding: false,
      label: InquriesTableHeader.Date,
    },
    {
      id: InquriesTable.Action,
      numeric: true,
      disablePadding: false,
      label: InquriesTableHeader.Action,
    },
  ];

  useEffect(() => {
    getinquiries([], date);
  }, [page, limit, total]);

  const getinquiries = async (value: any, dateString: any) => {
    const result: InquiriesResponse = await Service.getInstance().getInquiries(
      dateString,
      page + 1,
      limit
    );
    setDate(dateString);
    setInquiries(result.inquiries);
    setTotalInquiries(result.total ?? 0);
  };

  const deleteId = async (id: string) => {
    try {
      setOpen(false);
      await Service.getInstance().delete(id, Routes.getInquiries);
    } catch (error) {
      setTotalInquiries(total - 1);
      console.log(error);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isEvenOrOdd = (index: number) => {
    return index % 2 !== 0;
  };

  return (
    <>
      <div className="immigrants-wrapper">
        <Box>
          <Box>
            <TableContainer>
              <Table size="small"
                            sx={{ tableLayout: "fixed" }}
                            >
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={5} padding="none">
                      <Typography
                        variant="h5"
                        my={3}
                        textAlign={"left"}
                        className="page-title"
                      >
                        {GlobalTitles.Inquiries}
                      </Typography>
                      <Box  my={2} display={'flex'} alignItems={"center"} justifyContent={'space-between'}>
                        <RangePicker
                          allowClear={false}
                          id="date"
                          name="date"
                          size="large"
                          style={{ width: "400px" }}
                          onChange={getinquiries}
                        />
                        <Export exportRequest={Routes.getInquiries} />
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    className="table-headers"
                    sx={{ bgcolor: globalColors.tableHeadBg }}
                  >
                    {headCells.map((headCell) => (
                      <LightTooltip title={headCell.label} placement="top">
                        <TableCell
                          key={headCell.id}
                          align={"center"}
                          padding={"normal"}
                          sortDirection={false}
                          sx={{fontWeight: "bold"}}
                        >
                          {headCell.label}
                        </TableCell>
                      </LightTooltip>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inquiries?.map((row, index) => {
                    const tableRowStyle = "researcher-row ";
                    const tableRowBlueStyle =
                      "researcher-row table-row-bg-blue";
                    const displayRow = (
                      <TableRow
                        className={
                          isEvenOrOdd(index) ? tableRowBlueStyle : tableRowStyle
                        }
                      >
                        <TableCell align="center">{row.header}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.content}</TableCell>
                        <TableCell align="center">
                          {new Date(Object(row.date)).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setID(row._id);
                                setHeader(row.header);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                    return displayRow;
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display={"flex"} justifyContent={"flex-end"}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                className="pagination"
              />
            </Box>
          </Box>
        </Box>
      </div>
      <ConfirmBox
        open={open}
        closeDialog={() => setOpen(false)}
        title={header}
        deleteFunction={() => deleteId(id)}
      ></ConfirmBox>
    </>
  );
};
