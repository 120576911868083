import React, { useState } from "react";
import { useForm, Resolver } from "react-hook-form";
import "./OtpValidation.scss";
import { useNavigate } from "react-router-dom";
import { GlobalInputLabels, GlobalMessages } from "../../../commons/I18n";
import { FormErrors, GlobalTitles } from "../../../commons/enums";
import { Otp } from "../../../commons/types/LoginPropsTypes";
import { AuthService } from "../../../services";

export const OtpValidation = () => {
  const [showOtpError, setShowOtpError] = useState(false);
  const navigate = useNavigate();
  

  const resolver: Resolver<Otp> = async (values: Otp) => {

    return {
      values: values.otp ? values : {},
      errors: !values.otp
        ? {
            otp: {
              type: "required",
              message: FormErrors.WrongOtp,
            },
          }
        : {},
    };
  };

  const {
    register,
    handleSubmit,
  } = useForm<Otp>({ resolver });

  const onSubmit = handleSubmit(async (data: Otp) => {
      try {
          const isVerfied = await AuthService.getInstance().handleOTP(data.otp);
          if(isVerfied){
            return navigate("/home", { replace: true });
          }else{
            setShowOtpError(true);
          }
      } catch (error) {
        console.log(error);
      }
  });
  const sendOtpagain = async () => {
    await AuthService.getInstance().resendOTP();
  };

  return (
    <div className="otp-validation-container">
      <div className="login-logo">
        <img src="../images/global/Aliyapp-Logo.png" alt="Aliyapp" />
      </div>
      <div className="validation-container">
        <div className="form-wrapper">
          <h1 className="otp-Title">{GlobalTitles.EnterOtp}</h1>
          <form onSubmit={onSubmit}>
            <div className="inputs-wrapper">
              <div className="form-input">
                <label htmlFor="username">{GlobalInputLabels.Otp}</label>
                <input
                        className={showOtpError ? "otpInputError" : ""}
                  type="text"
                  {...register("otp")}
                  minLength={6}
                  maxLength={6}
                  placeholder={"******"}
                />
              </div>
            </div>
            <div>
              <p  className="formError"> {showOtpError? FormErrors.WrongOtp : ""}</p>
            </div>
            <div className="form-actions">
              <button className="submit-form">{GlobalTitles.Login}</button>
            </div>
          </form>
          <div className="send-again-wrong-phone">
            <button className="send-again"  onClick={sendOtpagain}>{GlobalMessages.SendOtpAgain}</button>
          </div>
        </div>
      </div>
    </div>
  );
};
