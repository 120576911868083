import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Header } from "../Header/Header";
import { AuthGuardService } from "../../services/guard/AuthGuard.service";

const AuthRouteGuard = () => {
    const hasOTP = AuthGuardService.getInstance().isUserOTPAuthenticated();
    const loggedIn = AuthGuardService.getInstance().isUserLoggedIn()
    if (hasOTP && !loggedIn) {
        return <Navigate to="/otp"></Navigate> ;
    }
    else if (!hasOTP && loggedIn){
        return <><Header></Header></>;
    }else{
        return <Navigate to="/"></Navigate>;
    }
};

export default AuthRouteGuard;
