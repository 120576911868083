import { StorageKeys } from "../commons/enums";

export class LocalStorageService {
    private sessionStorage: Storage;
    private localStorage: Storage;

    constructor() {
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
    }

    clearLocalStorage(): void {
        this.localStorage.clear();
    }

    clearSessionStorage(): void {
        this.sessionStorage.clear();
    }

    getItemFromLocalStorage(key: string): string | null {
        return this.localStorage.getItem(key);
    }

    getItemFromSessionStorage(key: string): string | null {
        return this.sessionStorage.getItem(key);
    }

    setItemInLocalStorage(key: string, value: string): void {
        return this.localStorage.setItem(key, value);
    }

    setItemInSessionStorage(key: string, value: string): void {
        return this.sessionStorage.setItem(key, value);
    }

    keyFromLocalStorage(index: number): string | null {
        return this.localStorage.key(index);
    }

    keyFromSessionStorage(index: number): string | null {
        return this.sessionStorage.key(index);
    }

    removeItemFromLocalStorage(key: string): void {
        return this.localStorage.removeItem(key);
    }

    removeItemFromSessionStorage(key: string): void {
        return this.sessionStorage.removeItem(key);
    }

    getToken(tokenType: StorageKeys): string | null {
        if (tokenType === StorageKeys.AccessToken) {
            return this.getItemFromSessionStorage(StorageKeys.AccessToken);
        } else if (tokenType === StorageKeys.RefreshToken) {
            return this.getItemFromLocalStorage(StorageKeys.RefreshToken);
        } else {
            return null;
        }
    }

    removeToken(tokenType: StorageKeys): void {
        if (tokenType === StorageKeys.AccessToken) {
            this.removeItemFromSessionStorage(StorageKeys.AccessToken);
        } else if (tokenType === StorageKeys.RefreshToken) {
            this.removeItemFromLocalStorage(StorageKeys.RefreshToken);
        }
    }

    setToken(tokenType: StorageKeys, value: string): void {
        if (tokenType === StorageKeys.AccessToken) {
            this.setItemInSessionStorage(StorageKeys.AccessToken, value);
        } else if (tokenType === StorageKeys.RefreshToken) {
            this.setItemInLocalStorage(StorageKeys.RefreshToken, value);
        }
    }
}
