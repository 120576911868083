import { Close, Delete, ModeEditOutline } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { MentorsModel } from "../../Models/Mentors.model";
import { MentorsTableHeader } from "../../commons/I18n";
import { Routes } from "../../commons/enums";
import { MentorsTable } from "../../commons/enums/GlobalTableHeaders.enum";
import { GlobalTitles } from "../../commons/enums/Titles.enum";
import {
  MentorsResponse,
  MentorsTableType,
} from "../../commons/types/MentorsTypes";
import { globalColors } from "../../globalColors";
import { ConfirmBox } from "../../model/ConfirmBox";
import { Service } from "../../services/service.service";
import { Export } from "../Export/Export";
import Filter from "../Filter/Filter";
import { LightTooltip } from "../LightTooltip/LightTooltip";
import Search from "../Search/Search";

export const Mentors = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [mentors, setMentors] = useState([] as MentorsModel[]);
  const [total, setTotalMentors] = useState(0);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setopenEdit] = useState(false);
  const [id, setID] = useState("");
  const [name, setName] = useState("");

  // Table header
  const headCells: readonly MentorsTableType[] = [
    {
      id: MentorsTable.FullName,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.FullName,
    },
    {
      id: MentorsTable.Email,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.Email,
    },
    {
      id: MentorsTable.Phone,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.Phone,
    },
    {
      id: MentorsTable.City,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.City,
    },
    {
      id: MentorsTable.Profession,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.Profession,
    },
    {
      id: MentorsTable.Language,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.Language,
    },
    {
      id: MentorsTable.Organization,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.Organization,
    },
    {
      id: MentorsTable.RouteType,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.RouteType,
    },
    {
      id: MentorsTable.RegistrationDate,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.RegistrationDate,
    },
    {
      id: MentorsTable.ImmigrantName,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.ImmigrantName,
    },
    {
      id: MentorsTable.ImmigrantPhone,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.ImmigrantPhone,
    },
    {
      id: MentorsTable.HasConnection,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.HasConnection,
    },
    {
      id: MentorsTable.Action,
      numeric: true,
      disablePadding: false,
      label: MentorsTableHeader.Action,
    },
  ];

  useEffect(() => {
    setMentors(mentors);
  }, [mentors]);

  useEffect(() => {
    getmentors();
  }, [page, limit, total]);

  const getmentors = async () => {
    const result: MentorsResponse = await Service.getInstance().getMentors(
      page + 1,
      limit
    );
    setMentors(result.mentors);
    setTotalMentors(result.total);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleFilter = (routeType: string) => {
    setSearch(routeType);
  };

  const data = {
    nodes: mentors.filter((item) => {
      return (
        item?.name?.toString().toLowerCase().indexOf(search.toLowerCase()) >
          -1 ||
        Object(item?.user)
          ?.email?.toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1 ||
        (item.chooseImmigrant
          ? Object.values(item.chooseImmigrant).join(" ")
          : ""
        )
          ?.toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1
      );
    }),
  };

  const deleteId = async (id: string) => {
    try {
      setOpen(false);
      await Service.getInstance().delete(id, Routes.deleteMentors);
    } catch (error) {
      setTotalMentors(total - 1);
      console.log(error);
    }
  };

  const editFunction = async (id: string) => {
    setopenEdit(false);
    await Service.getInstance().updateMentorName(
      id,
      name,
      Routes.updateMentorField
    );
    await getmentors();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isEvenOrOdd = (index: number) => {
    return index % 2 !== 0;
  };

  return (
    <>
      <div className="immigrants-wrapper">
        <Box>
          <Box>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                sx={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={13} padding="none">
                      <Typography
                        textAlign={"left"}
                        variant="h5"
                        my={3}
                        className="page-title"
                      >
                        {GlobalTitles.Mentors}
                      </Typography>

                      <Box
                        display={"flex"}
                        gap={1}
                        my={2}
                        flex={"1 0 50%"}
                        alignItems={"center"}
                      >
                        <Search handleSearch={handleSearch} />
                        <Filter setSearch={setSearch}>
                          <MenuItem value="">{"All"}</MenuItem>
                          <MenuItem value="connected">
                            {"Has Connection"}
                          </MenuItem>
                        </Filter>
                        <Box
                          flexBasis={"80%"}
                          display={"flex"}
                          justifyContent={"end"}
                        >
                          <Export exportRequest={Routes.getMentors} />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    className="table-headers"
                    sx={{ bgcolor: globalColors.tableHeadBg }}
                  >
                    {headCells.map((headCell) => (
                      <LightTooltip title={headCell.label} placement="top">
                        <TableCell
                          key={headCell.id}
                          align={"center"}
                          padding={"normal"}
                          sortDirection={false}
                          sx={{ fontWeight: "bold" }}
                        >
                          {headCell.label}
                        </TableCell>
                      </LightTooltip>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data["nodes"].map((row, index) => {
                    const tableRowStyle = "researcher-row ";
                    const tableRowBlueStyle =
                      "researcher-row table-row-bg-blue";
                    const displayRow = (
                      <TableRow
                        className={
                          isEvenOrOdd(index) ? tableRowBlueStyle : tableRowStyle
                        }
                      >
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">
                          {Object(row.user).email}
                        </TableCell>
                        <TableCell align="center">
                          {Object(row.user).phoneNumber}
                        </TableCell>
                        <TableCell align="center">
                          {row.residenceCity ? row.residenceCity + " " : " "}
                        </TableCell>
                        <TableCell align="center">
                          {row.professionalField
                            ? row.professionalField + " "
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          {row.languages + " "}
                        </TableCell>
                        <TableCell align="center">
                          {row.organizationName}
                        </TableCell>
                        <TableCell align="center">
                          {row.escortRoute ? row.escortRoute + " " : ""}
                        </TableCell>
                        <TableCell align="center">
                          {new Date(
                            Object(row.user)?.registrationDate
                          ).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="center">
                          {row.immigrantsDetails
                            ? Object(row.immigrantsDetails)
                                .map((obj: { name: any }) => obj.name)
                                .join(", ")
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          {row.immigrantsDetails
                            ? Object(row.immigrantsDetails)
                                .map(
                                  (obj: { user: any }) => obj.user.phoneNumber
                                )
                                .join(", ")
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          {row.chooseImmigrant
                            ? Object.values(row.chooseImmigrant).join(", ")
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          <Box display={"flex"} justifyContent={"space-evenly"}>
                            <IconButton
                              onClick={() => {
                                setopenEdit(true);
                                setID(Object(row.user)._id);
                              }}
                            >
                              <ModeEditOutline />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setID(Object(row.user)._id);
                                setName(row.name);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                    return displayRow;
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Box justifyContent={"flex-end"} display={"flex"}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                className="pagination"
              />
            </Box>
          </Box>
        </Box>
      </div>
      <ConfirmBox
        open={open}
        closeDialog={() => setOpen(false)}
        title={name}
        deleteFunction={() => deleteId(id)}
      ></ConfirmBox>
      <Dialog
        fullWidth
        open={openEdit}
        maxWidth="md"
        scroll="body"
        onClose={() => setopenEdit(false)}
        onBackdropClick={() => setopenEdit(false)}
      >
        <DialogContent sx={{ px: 8, py: 6, position: "relative" }}>
          <IconButton
            size="medium"
            onClick={() => setopenEdit(false)}
            sx={{ position: "absolute", right: "1rem", top: "1rem" }}
          >
            <Close />
          </IconButton>

          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box
                sx={{
                  mb: 3,
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h5">Edit Name</Typography>
                <br />
                <Typography variant="body1">
                  Are you sure you want to edit this name?
                  <input
                    style={{ textAlign: "center" }}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </Typography>
                <></>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
            >
              <Button
                style={{ backgroundColor: globalColors.buttonBg }}
                onClick={() => setopenEdit(false)}
                size="medium"
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                onClick={() => editFunction(id)}
                size="medium"
                variant="contained"
                color="error"
              >
                Edit
              </Button>{" "}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
