// import { Pie } from "../Statistics/Pie";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { PieModel } from "../../Models/Statistics.model";
import { ImmigrantResponse } from "../../commons/types/ImmigrantsTypes";
import { StatisticsResponse } from "../../commons/types/StatisticsTypes";
import { Service } from "../../services/service.service";
import { HalfPie } from "./HalfPie";
import NumberStat from "./NumberStat";
import NumberStatBox from "./NumberStatBox";
import { Pie } from "./Pie";
import StatBox from "./StatBox";
import { StatisticsCharts } from "../../commons/enums/Statistics.enum";

export const Statistics = () => {
  const [mentorsCity, setMentorsCity] = useState([] as PieModel[]);
  const [mentorsOrganization, setMentorsOrganization] = useState(
    [] as PieModel[]
  );
  const [mentorsRegion, setMentorsRegion] = useState([] as PieModel[]);
  const [immigrantsOrigin, setImmigrantsOrigin] = useState([] as PieModel[]);
  const [immigrantsRouteType, setImmigrantsRouteType] = useState(
    [] as PieModel[]
  );
  const [mentorRouteType, setMentorRouteType] = useState([] as PieModel[]);
  const [immigrants, setImmigrants] = useState({ total: 0 });
  const [counter, setCounter] = useState(0);
  const [avg, setAvg] = useState([] as PieModel[]);
  const [numOfmatching, setnumOfmatching] = useState("0");
  const [immigrantProfessional, setImmigrantByProfessional] = useState(
    [] as PieModel[]
  );
  const [mentorProfessional, setmentorByProfessional] = useState(
    [] as PieModel[]
  );
  const [immigrantMedical, setimmigrantByMedical] = useState(
    [] as PieModel[]
  );
  const [mentorMedical, setmentorByMedical] = useState(
    [] as PieModel[]
  );
  
  useEffect(() => {
    // getMentorsByRegion();
    avgMentorPerImmigrant();
    getMentorWithoutImmigrant();
    numOfMatching();
    getImmigrantNumber();
    getMentorByOrganization();
    getMentorsByCity();
    immigrantByProfessional();
    mentorByProfessional();
    immigrantByMedical();
    mentorByMedical();
    getmentorRouteType();
    getImmigrantByCountry();
    getImmigrantByRouteType();
  }, []);

  // const getMentorsByRegion= async () => {
  //     const result: StatisticsResponse  =
  //     await Service.getInstance().getMentorByRegion();
  //     setMentorsRegion(result.result);
  //     };

  const getMentorsByCity = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().getMentorByCity();
    if (result.result[0].id != "") setMentorsCity(result.result);
  };

  const getMentorWithoutImmigrant = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().getMentorWithoutImmigrant();
    result.result.map((key) => {
      setCounter(key.contacted);
    });
  };

  const getMentorByOrganization = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().getMentorByOrganization();
    if (result.result[0].id != "") setMentorsOrganization(result.result);
  };

  const getImmigrantByCountry = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().getImmigrantByCountry();

    const format = result.result.map((item) => {
      var label = item.label?.split(/(?=[A-Z])/).join(" ");
      return { ...item, label: label };
    });
    if (result.result[0].id != "") setImmigrantsOrigin(format);

    // if (result.result[0].id != "") setImmigrantsOrigin(result.result);
  };

  const getImmigrantNumber = async () => {
    const result: ImmigrantResponse = await Service.getInstance().getImmigrants(
      1,
      0
    );
    setImmigrants(result);
  };

  const getImmigrantByRouteType = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().getImmigrantByRouteType();
    if (result.result[0].id != "") setImmigrantsRouteType(result.result);
  };

  const getmentorRouteType = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().getMentorByRouteType();
    if (result.result[0].id != "") setMentorRouteType(result.result);
  };

  const avgMentorPerImmigrant = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().getMentorPerImmigrant();
    setAvg(result.result);
  };

  const immigrantByProfessional = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().immigrantByProfessional();
    setImmigrantByProfessional(result.result);
  };

  const mentorByProfessional = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().mentorByProfessional();
    setmentorByProfessional(result.result);
  };

  const immigrantByMedical = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().immigrantByMedical();
      setimmigrantByMedical(result.result);
  };

  const mentorByMedical = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().mentorByMedical();
      setmentorByMedical(result.result);
  };

  const numOfMatching = async () => {
    const result: StatisticsResponse =
      await Service.getInstance().numOfMatching();
    result.result.map((key) => {
      setnumOfmatching(key.numOfmatching.toString());
    });
  };

  return (
    <Box
      display="flex"
      gap={2}
      flexWrap={"wrap"}
      alignItems={"flex-start"}
      p={2}
    >
      <StatBox title={StatisticsCharts.ImmigrantsByCity}>
        <Pie data={immigrantsOrigin} />
      </StatBox>
      
      <StatBox title={StatisticsCharts.ImmigrantsByProfessional}>
        <Pie data={immigrantProfessional} />
      </StatBox>

      <StatBox title={StatisticsCharts.ImmigrantsByMedical}>
        <Pie data={immigrantMedical} />
      </StatBox>

      <StatBox title={StatisticsCharts.ImmigrantsByRouteType}>
        <HalfPie data={immigrantsRouteType} />
      </StatBox>

      <StatBox title={StatisticsCharts.MentorsByCity}>
        <Pie data={mentorsCity} />
      </StatBox>

      <StatBox title={StatisticsCharts.MentorsByProfessional}>
        <Pie data={mentorProfessional} />
      </StatBox>

      <StatBox title={StatisticsCharts.MentorsByMedical}>
        <Pie data={mentorMedical} />
      </StatBox>

      <StatBox title={StatisticsCharts.MentorsByRouteType}>
        <HalfPie data={mentorRouteType} />
      </StatBox>
      
      <NumberStatBox>
        <NumberStat
          number={Number(numOfmatching)}
          text={StatisticsCharts.NumberOfMatching}
        />
        <NumberStat
          number={Number(avg)}
          text={StatisticsCharts.AverageMentorsPerImmigrant}
        />
      </NumberStatBox>
      <NumberStatBox>
        <NumberStat
          number={immigrants?.total ?? 0}
          text={StatisticsCharts.TheNumberOfImmigrantsRegistered}
        />
        <NumberStat
          number={counter}
          text={StatisticsCharts.RegisteredMentorsWithoutAssociatedImmigrants}
        />
      </NumberStatBox>
    </Box>
  );
};
