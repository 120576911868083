export enum StatisticsCharts {
  ImmigrantsByCity = "Olim By Country",
  ImmigrantsByRouteType = "Olim By Route Type",
  ImmigrantsByProfessional = "Olim By Professional",
  ImmigrantsByMedical = "Olim By Medical Field",
  MentorsByMedical= "Mentor By Medical Field",
  MentorsByCity = "Mentors By City",
  MentorsByProfessional = "Mentors By Professional",
  MentorsByRouteType = "Mentors by Route Type",
  NumberOfMatching = "Number Of Matching",
  AverageMentorsPerImmigrant = "Average mentors per immigrant",
  TheNumberOfImmigrantsRegistered = "The number of olim that registered",
  RegisteredMentorsWithoutAssociatedImmigrants = "Registered mentors without associated olim",
}
