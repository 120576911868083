import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { WeightFieldModel } from "../../Models/WeightField.model";
import { WeightFieldTableHeader } from "../../commons/I18n";
import { Routes } from "../../commons/enums";
import { WeightFieldTable } from "../../commons/enums/GlobalTableHeaders.enum";
import { GlobalTitles } from "../../commons/enums/Titles.enum";
import {
  WeightFieldResponse,
  WeightFieldTableType,
} from "../../commons/types/WeightFieldTypes";
import { globalColors } from "../../globalColors";
import { Service } from "../../services/service.service";
import { LightTooltip } from "../LightTooltip/LightTooltip";

export const WeightField = () => {
  const [weight, setWeight] = useState(0);
  const [type, setType] = useState("match able");
  const [weightField, setweightField] = useState([] as WeightFieldModel[]);

  // Table header
  const headCells: readonly WeightFieldTableType[] = [
    {
      id: WeightFieldTable.FieldName,
      numeric: true,
      disablePadding: false,
      label: WeightFieldTableHeader.FieldName,
    },
    {
      id: WeightFieldTable.Weight,
      numeric: true,
      disablePadding: false,
      label: WeightFieldTableHeader.Weight,
    },
    {
      id: WeightFieldTable.Type,
      numeric: true,
      disablePadding: false,
      label: WeightFieldTableHeader.Type,
    },
    {
      id: WeightFieldTable.Action,
      numeric: true,
      disablePadding: false,
      label: WeightFieldTableHeader.Action,
    },
  ];

  useEffect(() => {
    getWeightField();
  }, []);

  const getWeightField = async () => {
    const result: WeightFieldResponse =
      await Service.getInstance().getWeightField();
    setweightField(result.WeightField);
  };

  const EnhancedTableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align="center" colSpan={4} padding="none">
            <Typography
              variant="h5"
              my={3}
              textAlign={"left"}
              color={globalColors.headerColor}
            >
              {GlobalTitles.WeightField}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow sx={{ bgcolor: globalColors.tableHeadBg }}>
          {headCells.map((headCell) => (
            <LightTooltip title={headCell.label} placement="top">
              <TableCell
                key={headCell.id}
                align={"center"}
                padding={"normal"}
                sortDirection={false}
                sx={{fontWeight: "bold"}}
              >
                {headCell.label}
              </TableCell>
            </LightTooltip>
          ))}
        </TableRow>
      </TableHead>
    );
  };
  const handleWeight = (newWeight: number) => {
    setWeight(newWeight);
  };

  const handleType = (newType: string) => {
    setType(newType);
  };

  const updateField = async (
    type: string,
    weight: number,
    obj: WeightFieldModel
  ) => {
    await Service.getInstance().update(
      obj._id,
      type,
      weight,
      Routes.updateWeightField
    );
  };

  const isEvenOrOdd = (index: number) => {
    return index % 2 !== 0;
  };

  return (
    <div className="immigrants-wrapper">
      <Box>
        <Box>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              sx={{ tableLayout: "fixed" }}
            >
              <EnhancedTableHead />
              <TableBody>
                {weightField.map((row, index) => {
                  const tableRowStyle = "researcher-row ";
                  const tableRowBlueStyle = "researcher-row table-row-bg-blue";
                  const displayRow = (
                    <TableRow
                      className={
                        isEvenOrOdd(index) ? tableRowBlueStyle : tableRowStyle
                      }
                    >
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">
                        <input
                          style={{ textAlign: "center" }}
                          defaultValue={row.weight}
                          onChange={(e) => handleWeight(Number(e.target.value))}
                        ></input>
                      </TableCell>
                      <TableCell align="center">
                        <input
                          style={{ textAlign: "center" }}
                          defaultValue={row.type}
                          onChange={(e) => handleType(e.target.value)}
                        ></input>
                      </TableCell>
                      <TableCell align="center">
                        <div>
                          <button
                            className="edit-save-btn"
                            onClick={(e) => updateField(type, weight, row)}
                          >
                            Submit
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                  return displayRow;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  );
};
