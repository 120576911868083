import { GlobalErrors } from "../../commons/I18n";
const ERROR_GENERAL = 'ge';
const ERROR_LOGIN = 'lg';

export const ErrorManager = (location: string, codeNumber: number) => {
    /**
    * Note: please keep this error map maintained when adding new errors.
    *
    * Error chars map:
    * gn - general errors.
    * lg - login page.
    *
    */

     if (location === ERROR_GENERAL) {
        switch (codeNumber) {
        case 1:
            return '';
        }
    }
        
    if (location === ERROR_LOGIN) {
        switch (codeNumber) {
        case 1:
            return '';
        }
    }

    //should not reach this stage but here in case an error was not handled with a switch.
    return GlobalErrors.GeneralError;
}