import {
  Box,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { GlobalTitles } from "../../commons/enums";
import { SetStateAction } from "react";

const handleFilter = (
  event: SelectChangeEvent,
  setSearch: React.Dispatch<SetStateAction<string>>
) => {
  var routeType = event.target.value as string;
  setSearch(routeType);
};

export default function Filter({
  children,
  setSearch,
}: {
  children: React.ReactNode;
  setSearch: React.Dispatch<SetStateAction<string>>;
}) {
  return (
    <Box>
      <Box maxWidth={"120px"} minWidth={"120px"}>
        <FormControl fullWidth size="small">
          <InputLabel>{GlobalTitles.Filter}</InputLabel>
          <Select
            size="small"
            onChange={(e: SelectChangeEvent) => handleFilter(e, setSearch)}
          >
            {children}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
