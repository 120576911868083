import { CreateImmigrants } from "../../components/Immigrants/CreateImmigrants/CreateImmigrants";


export enum GlobalTitles {
    Login = 'Login',
    EnterPhone = 'Enter Phone',
    EnterOtp = 'Enter Otp',
    Export = 'Export',
    Filter = 'Filter',
    SortBy = 'Sort By',
    Immigrants = 'Olim',
    Mentors= 'Mentors',
    Inquiries = 'Inquiries',
    JoinRequests = 'Join Requests',
    Surveys = 'Surveys',
    WeightField = 'WeightField',
    Organizations = 'Organizations',
    CreateOrganizations = 'Create',
    CreateImmigrant = 'Create Oleh',
    CreateImmigrants = 'Create Olim'
}