import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthGuardService } from "../../services/guard/AuthGuard.service";

const OTPRouteGuard = () => {
    const hasOTP = AuthGuardService.getInstance().isUserOTPAuthenticated();
    const loggedIn = AuthGuardService.getInstance().isUserLoggedIn();
    if (hasOTP && !loggedIn) {
        return <Outlet />;
    }
    if (!hasOTP && loggedIn) {
        return <Navigate to="/home"></Navigate>; //  TODO:change to home when there is a home page
    }

    return <Navigate to="/"></Navigate>;
};

export default OTPRouteGuard;
