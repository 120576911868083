
import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Home } from "./components/home/Home";
import { Login } from "./components/login/Login";
import OTPRouteGuard from "./components/Guards/OTPRouteGuard";
import { OtpValidation } from "./components/login/OtpValidation/OtpValidation";
import AuthRouteGuard from "./components/Guards/AuthRouteGuard";

export const Router: React.FC = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route element={<OTPRouteGuard />}>
                        <Route path="/otp" element={<OtpValidation />} />
                    </Route>
                <Route element={<AuthRouteGuard />}>
                    <Route path="/home" element={<Home />} />
                </Route>
            </Routes>
        </BrowserRouter>    
    )
}