export enum ImmigrantsTable {
    FullName = "FullName",
    Email = "Email",
    Phone = "Phone",
    Country = "Country",
    Language = "Language",
    RegistrationDate = "RationDate",
    RouteType = "RouteType",
    MentorName = "MentorName",
    MentorPhone = "MentorPhone",
    Professional = "Professional"
}

export enum MentorsTable {
    FullName = "FullName",
    Email = "Email",
    Phone = "Phone",
    RegistrationDate = "RegistrationDate",
    City = "City",
    Profession = "Profession",
    Language = "Language",
    Organization = "Organization",
    RouteType = "RouteType",
    ImmigrantName = "immigrantName",
    ImmigrantPhone = "immigrantPhone",
    HasConnection = "hasConnection",
    Action = "Action",
}

export enum InquriesTable {
    Header = "Header",
    Email = "Email",
    Date = "Date",
    Content = "Content",
    Action = "Action",
}

export enum JoinRequestsTable {
    FullName = "FullName",
    PhoneNumber = "PhoneNumber",
    Date = "Date",
    DesiredPermission = "DesiredPermission",
    Action = "Action",
    HeardFrom = "HeardFrom",
    Email = "Email",
    Country = "Country",
}

export enum SurveysTable {
    Permission = "Permission",
    FullName =  "FullName",
    HisMatch =  "HisMatch",
    SurveyAnswer = "SurveyAnswer",
    Date = "Date",
}

export enum WeightFieldTable{
    Id =  "Id",
    FieldName =  "FieldName",
    Weight =  "Weight",
    Type = "Type",
    Action = "Action",
}

export enum OrganizationsTable {
    OrganizationName = "OrganizationName",
    ContactName = "ContactName",
    NumberOfinvitations = "NumberOfInvitations",
    NumberOfRegisteredMentors = "NumberOfRegisteredMentors",
    ActiveEscortMentors = "ActiveEscortMentors",
    Role = "Role",
    Email = "Email",
    Phone = "Phone",
    Action = "Action",
}
