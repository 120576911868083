import axios from "axios";
import { AxiosInstance, AxiosError } from "axios";
import rateLimit from "axios-rate-limit";
import { environment } from "../../environments/Environment";
import * as ApiInterfaces from "../../commons/interfaces/Api.interfaces";
import { LocalStorageService } from "../LocalStorage.service";
import * as I18n from "../../commons/I18n";
import * as ApiEnums from "../../commons/enums/Api.enums";
import { ErrorManager } from "../../services/errors/ErrorsManager.service";
import { StorageKeys } from "../../commons/enums";
import { AuthService } from "./AuthService.service";

const MAX_REQUESTS = 2;
const MAX_RPS = 2;
const PER_MILLISECONDS = 500;

class ApiService {
    private axiosClient: AxiosInstance;
    private localStorageService: LocalStorageService =
        new LocalStorageService();
    static instance: ApiService;

    constructor() {
        // The ApiClient wraps calls to the underlying Axios client.
        this.axiosClient = rateLimit(
            axios.create({
                // timeout: environment.REQUEST_TIMEOUT,
                headers: {
                    [ApiEnums.RequestEnum.XInitializedAt]:
                        Date.now().toString(),
                },
            }),
            {
                maxRequests: MAX_REQUESTS,
                perMilliseconds: PER_MILLISECONDS,
                maxRPS: MAX_RPS,
            }
        );
    }

    //Api service singleton
    static getInstance() {
        if (!this.instance) {
            this.instance = new ApiService();
        }
        return this.instance;
    }

    // All odd responses will result in a null
    checkUndefinedREsponse = (response: any) => {
        if (
            response &&
            typeof response.data !== ApiEnums.ResponseEnum.Undefined
        ) {
            return response.data;
        }
        return null;
    };

    // ---
    // PUBLIC METHODS.
    // ---

    public async generalRequest<T>(
        method: string,
        route: string,
        data?: ApiInterfaces.Data
    ): Promise<T | string | ApiInterfaces.ErrorResponse> {
        try {
            switch (method) {
                case ApiEnums.RequestEnum.Get:
                    {
                        const response = await this.get<any>({
                            url: route,
                            params: data || {},
                        });
                        if (
                            response &&
                            typeof response.data !==
                                ApiEnums.ResponseEnum.Undefined
                        ) {
                            return response.data;
                        }
                    }
                    break;
                case ApiEnums.RequestEnum.Post:
                    if (data) {
                        const response = await this.post<any>({
                            url: route,
                            data,
                        });
                        if (
                            response
                             !==
                                ApiEnums.ResponseEnum.Undefined
                        ) {
                            return response
                        }
                    }
                    break;
                case ApiEnums.RequestEnum.Put:
                    if (data) {
                        const response = await this.put<any>({
                            url: route,
                            data,
                        });
                        if (
                            response &&
                            typeof response.data !==
                                ApiEnums.ResponseEnum.Undefined
                        ) {
                            return response.data;
                        }
                    }
                    break;
                case ApiEnums.RequestEnum.Delete:
                    if (data) {
                        const response = await this.delete<any>({
                            url: route,
                            data,
                        });
                        if (
                            response &&
                            typeof response.data !==
                                ApiEnums.ResponseEnum.Undefined
                        ) {
                            return response.data;
                        }
                    }
                    break;
            }
            return Promise.reject(ApiEnums.RequestEnum.ErrorBadRequest);
        } catch (error: any) {
            await this._interceptor(error);
            let errorContext: string = I18n.GlobalErrors.GeneralError;
            if (error && error.code) {
                errorContext = this.handleErrorCode(error.code);
            }
            if (!error.code) {
                return error;
            }
            console.error(
                ApiEnums.RequestEnum.ErrorOnReq +
                    route +
                    ApiEnums.RequestEnum.ErrorConcat +
                    errorContext
            );
            return errorContext;
        }
    }

    public async get<T>(options: ApiInterfaces.GetOptions): Promise<T> {
        try {
            var axiosResponse = await this.axiosClient.request<T>({
                method: ApiEnums.RequestEnum.Get,
                url: `${environment.API_URL}${options.url}`,
                params: options.params,
                headers: this.getHeaders(),
            });
            return axiosResponse.data;
        } catch (error) {
            return Promise.reject(this.normalizeError(error));
        }
    }

    public async post<T>(options: ApiInterfaces.PostOptions): Promise<T> {
        try {
            var axiosResponse = await this.axiosClient.request<T>({
                method: ApiEnums.RequestEnum.Post,
                url: `${environment.API_URL}${options.url}`,
                data: options.data,
                headers: this.getHeaders(),
            });
            return axiosResponse.data;
        } catch (error) {
            return Promise.reject(this.normalizeError(error));
        }
    }

    public async put<T>(options: ApiInterfaces.PostOptions): Promise<T> {
        try {
            var axiosResponse = await this.axiosClient.request<T>({
                method: ApiEnums.RequestEnum.Put,
                url: `${environment.API_URL}${options.url}`,
                data: options.data,
                headers: this.getHeaders(),
            });
            return axiosResponse.data;
        } catch (error) {
            return Promise.reject(this.normalizeError(error));
        }
    }

    public async delete<T>(options: ApiInterfaces.PostOptions): Promise<T> {
        try {
            var axiosResponse = await this.axiosClient.request<T>({
                method: ApiEnums.RequestEnum.Delete,
                url: `${environment.API_URL}${options.url}`,
                data: options.data,
                headers: this.getHeaders(),
            });
            return axiosResponse.data;
        } catch (error) {
            return Promise.reject(this.normalizeError(error));
        }
    }

    // ---
    // PRIVATE METHODS.
    // ---

    private async _interceptor(error?: any) {
        if (error === ApiEnums.ResponseEnum.Unauthorized) {
            await AuthService.getInstance().logout();
        }

        if (error.message === ApiEnums.ResponseEnum.Forbidden) {
            await AuthService.getInstance().logout();
        }
    }

    /* Errors can occur for a variety of reasons.
     * We will normalize the error response so that
     * the calling context can assume a standard error structure.
     */
    private normalizeError(error: any): ApiInterfaces.ErrorResponse {
        const err = error as AxiosError;
        if (err.response && err.response.data) {
            return err.response.data as ApiInterfaces.ErrorResponse;
        }
        return {
            code: ApiEnums.ResponseEnum.UnknownError,
            message: ApiEnums.ResponseEnum.UnknownErrorMessage,
        };
    }

    // Required for authorization
    private getHeaders() {
        const idToken = this.localStorageService.getToken(
            StorageKeys.AccessToken
        );
        return idToken
            ? { Authorization: `${ApiEnums.RequestEnum.Bearer} ${idToken}` }
            : {};
    }

    //error code must begin with 2 chars followed by a number, chars will indicate the related page/feature.
    handleErrorCode(code: string): string {
        if (code.length < 3) {
            return I18n.GlobalErrors.GeneralError;
        }
        let location = code.substring(0, 2).toLowerCase();
        let strNumber = code.substring(2);
        const allowedChars = /^[a-z]+$/;
        const allowedNumbers = /^\d+$/;
        if (!allowedChars.test(location) || !allowedNumbers.test(strNumber)) {
            return I18n.GlobalErrors.GeneralError;
        }
        let codeNumber = Number(strNumber);
        return ErrorManager(location, codeNumber);
    }
}

export default ApiService.getInstance();
