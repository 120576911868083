import React, { useState } from "react";
import "../../Style/CreateStyle.scss";
import { GlobalTitles } from "../../../commons/enums";
import { Form, Input, Modal, Select } from "antd";
import { Service } from "../../../services/service.service";
import { Box, Button } from "@mui/material";
import { globalColors } from "../../../globalColors";



interface CreateOrganizationProps {
  setCreatedNew: (isNew:boolean) => void;
}

export const CreateOrganization:React.FC<CreateOrganizationProps> = ({setCreatedNew}) => {
  const CollectionCreateForm = ({
    visible,
    onCreate,
    onCancel,
    showError,
    response,
  }: {
    visible: any;
    response: string;
    showError: boolean;
    onCreate: any;
    onCancel: any;
  }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        visible={visible}
        title="Create"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="organizationName"
            label="Organization Name"
            rules={[
              {
                required: true,
                message: "Please enter organization name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="contactName"
            label="contactName"
            rules={[
              {
                required: true,
                message: "Please enter contact name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* User */}
          <Form.Item
            name="role"
            label="Role"
            rules={[
              {
                required: true,
                message: "Please enter rule!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter email!",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="Phone"
            rules={[
              {
                required: true,
                message: "Please enter phone!",
              },
              {
                pattern: /^[0-9]{3}[0-9]{3}[0-9]{4}$/,
                message: "Please enter phone!",
              },
            ]}
          >
            <Input
              style={{
                marginBottom: "20px",
              }}
            />
            {showError && (
              <p
                style={{
                  marginTop: "20px",
                  padding: "20px",
                  marginBottom: "20px",
                }}
                className="formError"
              >
                {response}
              </p>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  interface CollectionsPageProps {
    setCreatedNew: (isNew:boolean) => void;
  }

  const CollectionsPage:React.FC<CollectionsPageProps> = ({setCreatedNew}) => {
    const [visible, setVisible] = useState(false);
    const [response, setResponse] = useState("");
    const [showError, setShowError] = useState(false);

    const onCreate = async (values: any) => {
      console.log("Received values of form: ", values);
      const res = await Service.getInstance().createOrganization(values);
      setCreatedNew(true)
      
      if (typeof res === "string") {
        setResponse(res);
        setShowError(true);
      } else {
        setVisible(false);
      }
    };

    return (
      <div>
        <Box textAlign="right">
          <Button
            sx={{ textTransform: "capitalize", bgcolor: globalColors.buttonBg }}
            variant="contained"
            disableElevation
            onClick={() => {
              setVisible(true);
              setShowError(false);
            }}
          >
            <span>{GlobalTitles.CreateOrganizations}</span>
          </Button>
        </Box>
        <CollectionCreateForm
          visible={visible}
          onCreate={onCreate}
          showError={showError}
          response={response}
          onCancel={() => {
            setVisible(false);
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <CollectionsPage  setCreatedNew={setCreatedNew}/>
    </div>
  );
};
