export enum Routes {
    // ###### AUTH ######
    postLogin = "super-admin/login",
    postForgotPassword = "auth/forgot-password",
    postOTP = "auth/otp",
    postResendOtp = "auth/resend-otp",
    postLogout = "auth/logout",
    postRefreshRT = "auth/refreshToken",
    postRegister = "auth/register",
    postCsvImmigrants = "auth/upload",
    POST_LOGOUT = "auth/logout",
    POST_ORGANUZATUIN = "organizations/create",

    // ###### GENERAL RESEARCHERS ######
    getImmigrants = "immigrants/getAll",
    getMentors = "mentors/getAll",
    getInquiries = "inquiries",
    getJoinRequests = "joinRequest",
    getSurveys = "surveys",
    getWeightField = "WeightField/getAll",
    getOrganizations = "organizations/getAll",

    //Delete
    deleteWeightField = "WeightField/deleteById",
    deleteOrganizations = "organizations/deleteById",
    deleteMentors = "mentors/deleteById",

    //Put
    updateWeightField = "WeightField/updateById",
    updateOrganizationsField = "organizations/updateById",
    updateMentorField = "mentors/updateById",


    // ###### Statistics ######
    getMentorByCity = "statistics/mentorByCity",
    getMentorByOrganization = "statistics/mentorByOrganization",
    getMentorWithoutImmigrant = "statistics/mentorWithoutImmigrant",
    numOfMatching = "statistics/numOfMatching",
    getMentorByRouteType = "statistics/mentorByRouteType",
    getMentorByRegion = "statistics/mentorByRegion",
    getMentorPerImmigrant = "statistics/avgMentorPerImmigrant",
    getmentorByMedical = "statistics/mentorByMedical",
    getimmigrantByProfessional = "statistics/immigrantByProfessional",
    getmentorByProfessional = "statistics/mentorByProfessional",
    getimmigrantByMedical = "statistics/immigrantByMedical",
    getImmigrantByCountry = "statistics/immigrantByCountry",
    getImmigrantByRouteType= "statistics/immigrantByRouteType",
}
