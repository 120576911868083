import { Box, Paper, Typography } from "@mui/material";
import React from "react";

const StatBox: React.FC<any> = ({
  title,
  children,
}: {
  title: string;
  children: any;
}) => {
  return (
    <Paper
      elevation={1}
      sx={{ width: "466px", height: "365px", borderRadius: "15px" }}
    >
      <Typography variant="h6" textAlign={"left"} mb={2} marginX={3} mt={2}>
        <Typography component={"span"} variant={"inherit"} fontWeight={"bold"}>
          {title?.split(" ")[0] + " "}
        </Typography>
        {title?.split(" ").splice(1).join(" ")}
      </Typography>
      <Box width={"100%"} height={"100%"}>
        {children}
      </Box>
    </Paper>
  );
};

export default StatBox;
