
import React from 'react';
import { Header } from '../Header/Header';

export const Home = () => {    
    return (
        <div>
            <Header></Header>
        </div>
    );
};