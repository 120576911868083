import { Button } from "@mui/material";
import { DatePicker, Form, Input, Modal, Select } from "antd";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { GlobalTitles } from "../../../commons/enums";
import { globalColors } from "../../../globalColors";
import { Service } from "../../../services/service.service";
import "../../Style/CreateStyle.scss";
const { Option } = Select;

export const CreateImmigrants = () => {
  const CollectionCreateForm = ({
    visible,
    onCreate,
    onCancel,
    showError,
    response,
  }: {
    visible: any;
    response: string;
    showError: boolean;
    onCreate: any;
    onCancel: any;
  }) => {
    const [form] = Form.useForm();

    return (
      <Modal
        visible={visible}
        title="Create an immigrant"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="fullName"
            label="Full Name"
            style={{
              padding: "10px",
              width: "320px",
            }}
            rules={[
              {
                required: true,
                message: "Please enter full name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            style={{
              padding: "10px",
              width: "320px",
            }}
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter email!",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            style={{
              padding: "10px",
            }}
            name="phoneNumber"
            label="Phone"
            rules={[
              {
                required: true,
                message: "Please enter phone!",
              },
              {
                // pattern:/^[0-9]{3}[0-9]{3}[0-9]{4}$/,
                message: "Please enter phone!",
              },
            ]}
          >
            <PhoneInput country={"il"} />
          </Form.Item>

          <Form.Item
            name="systemLanguages"
            label="System Languages"
            style={{
              padding: "10px",
              width: "320px",
            }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <Option key="english_us" value="english_us">
                English (US)
              </Option>
              <Option key="english_uk" value="english_uk">
                English (UK)
              </Option>
              <Option key="french" value="french">
                French
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="birthday"
            label="birthday"
            rules={[
              {
                type: "object",
                required: true,
                message: "this_field_is_required",
              },
            ]}
            style={{
              padding: "20px",
            }}
          >
            <DatePicker
              format="DD-MM-YYYY"
              allowClear={false}
              style={{
                height: "auto",
                width: "auto",
                border: "none",
                borderRadius: "0px",
                cursor: "pointer",
                fontSize: "17px",
                margin: " 0px",
                padding: "0px",
              }}
            />
            {showError && <p className="formError">{response}</p>}
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const CollectionsPage = () => {
    const [visible, setVisible] = useState(false);
    const [response, setResponse] = useState("");
    const [showError, setShowError] = useState(false);

    const onCreate = async (values: Object) => {
      Object(values)["permission"] = "IMMIGRANT";
      Object(values)["phoneNumber"] = String(
        "+" + Object(values)["phoneNumber"]
      ).startsWith("+9720")
        ? String("+" + Object(values)["phoneNumber"]).replace("+9720", "+972")
        : String("+" + Object(values)["phoneNumber"]);
      const res = await Service.getInstance().createImmigrants(values);
      if (typeof res === "string") {
        setResponse(res);
        setShowError(true);
      } else {
        setVisible(false);
      }
    };

    return (
      <div>
        <Button
          variant="contained"
          disableElevation
          sx={{ textTransform: "capitalize", bgcolor: globalColors.buttonBg }}
          onClick={() => {
            setVisible(true);
            setShowError(false);
          }}
        >
          <span>{GlobalTitles.CreateImmigrant}</span>
        </Button>
        <CollectionCreateForm
          visible={visible}
          onCreate={onCreate}
          showError={showError}
          response={response}
          onCancel={() => {
            setVisible(false);
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <CollectionsPage />
    </div>
  );
};
