import { Box, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { GlobalTitles, Routes } from "../../commons/enums";
import { CSVFileName } from "../../commons/enums/FileName.enum";
import { ImmigrantResponse } from "../../commons/types/ImmigrantsTypes";
import { InquiriesResponse } from "../../commons/types/InquiriesTypes";
import { MentorsResponse } from "../../commons/types/MentorsTypes";
import { OrganizationsResponse } from "../../commons/types/OrganizationsType";
import { SurveysResponse } from "../../commons/types/SurveysTypes";
import { globalColors } from "../../globalColors";
import { Service } from "../../services/service.service";
import "./Export.scss";
import { JoinRequestsResponse } from "../../commons/types/JoinRequestsTypes";

export const Export = ({ exportRequest }: { exportRequest: Routes }) => {
  const [csvDataState, setCSVDataState] = useState([] as any);
  const [headers, setHeaders] = useState([] as any);
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const [fileNameState, setFileNameState] = useState("");

  useEffect(() => {
    if (csvDataState.length !== 0) {
      csvLinkRef?.current?.link.click();
    }
  }, [csvDataState]);

  const exportToCSV = async () => {
    try {
      if (exportRequest === Routes.getImmigrants) {
        const result: ImmigrantResponse =
          await Service.getInstance().getImmigrants(0, 0);
        const immigrants = result.immigrants.map((element) => ({
          name: element.name,
          email: Object(element.user).email,
          phoneNumber: Object(element.user).phoneNumber,
          country: element.originCountry,
          languages: element.languages,
          registRationDate: Object(element.user).registRationDate,
          escortRoute: element.escortRoute,
          professional: element.professionalField,
          mentorName: Object(element.mentor)?.name,
          mentorPhoneNumber: Object(element.mentor)?.user?.phoneNumber,
        }));
        const headers = [
          { label: "Name", key: "name" },
          { label: "Email", key: "email" },
          { label: "Phone Number", key: "phoneNumber" },
          { label: "Country", key: "country" },
          { label: "Languages", key: "languages" },
          { label: "Registration Date", key: "registRationDate" },
          { label: "Route", key: "escortRoute" },
          { label: "Professional", key: "professional" },
          { label: "Mentor Name", key: "mentorName" },
          { label: "Mentor PhoneNumber", key: "mentorPhoneNumber" },
        ];
        setHeaders(headers);
        setCSVDataState(immigrants);
        setFileNameState(CSVFileName.immigrants);
      }
      if (exportRequest === Routes.getMentors) {
        const result: MentorsResponse = await Service.getInstance().getMentors(
          0,
          0
        );
        const mentors = result.mentors.map((element) => ({
          name: element.name,
          email: Object(element.user).email,
          phoneNumber: Object(element.user).phoneNumber,
          city: element.residenceCity,
          professional: element.professionalField,
          languages: element.languages,
          organizationName: element.organizationName,
          escortRoute: element.escortRoute,
          registRationDate: Object(element.user).registRationDate,
          immigrantName: Object(element.immigrantsDetails)?.name,
          immigrantPhoneNumber: Object(element.immigrantsDetails)?.user
            ?.phoneNumber,
        }));
        const headers = [
          { label: "Name", key: "name" },
          { label: "Email", key: "email" },
          { label: "Phone Number", key: "phoneNumber" },
          { label: "City", key: "city" },
          { label: "Professional", key: "professional" },
          { label: "Languages", key: "languages" },
          { label: "Organization Name", key: "organizationName" },
          { label: "Route", key: "escortRoute" },
          { label: "Registration Date", key: "registRationDate" },
          { label: "Oleh Name", key: "immigrantName" },
          { label: "Oleh Phone Number", key: "immigrantPhoneNumber" },
        ];
        setHeaders(headers);
        setCSVDataState(mentors);
        setFileNameState(CSVFileName.mentors);
      }
      if (exportRequest === Routes.getInquiries) {
        const result: InquiriesResponse =
          await Service.getInstance().getInquiries([], 0, 0);
        const inquiries = result.inquiries.map((element) => ({
          header: element.header,
          email: element.name,
          content: element.content,
          date: element.date,
        }));
        const headers = [
          { label: "header", key: "header" },
          { label: "Email", key: "email" },
          { label: "Content", key: "content" },
          { label: "Date", key: "date" },
        ];
        setHeaders(headers);
        setCSVDataState(inquiries);
        setFileNameState(CSVFileName.inquiries);
      }
      if (exportRequest === Routes.getJoinRequests) {
        const result: JoinRequestsResponse =
          await Service.getInstance().getJoinRequests([], 0, 0);
        const joinRequests = result.joinRequests.map((element) => ({
          fullname: element.name,
          phonenumber: element.phoneNumber,
          desiredpermission: element.desiredPermission,
          date: element.date,
          howDidYouHearAboutAliyapp: element.howDidYouHearAboutAliyapp,
          countryOfAliyah: element.countryOfAliyah,
          email: element.email,
        }));
        
        const headers = [
          { label: "Full Name", key: "fullname" },
          { label: "Phone Number", key: "phonenumber" },
          { label: "Desired Permission", key: "desiredpermission" },
          { label: "Date", key: "date" },
          { label: "Hear From", key: "howDidYouHearAboutAliyapp" },
          { label: "Country", key: "countryOfAliyah" },
          { label: "Email", key: "email" },
        ];

        setHeaders(headers);
        setCSVDataState(joinRequests);
        setFileNameState(CSVFileName.joinRequests);
      }
      if (exportRequest === Routes.getSurveys) {
        const result: SurveysResponse = await Service.getInstance().getSurveys(
          [],
          0,
          0
        );
        const surveys = result.surveys.map((element) => ({
          header: Object(element.permission),
          immigrantName: Object(element.immigrantId).name,
          mentorName: Object(element.mentorId).name,
          surveyAnswer: element.surveyAnswer,
        }));
        const headers = [
          { label: "header", key: "header" },
          { label: "Oleh Name", key: "immigrantName" },
          { label: "Mentor Name", key: "mentorName" },
          { label: "SurveyAnswer", key: "surveyAnswer" },
        ];
        setHeaders(headers);
        setCSVDataState(surveys);
        setFileNameState(CSVFileName.surveys);
      }
      if (exportRequest === Routes.getOrganizations) {
        const result: OrganizationsResponse =
          await Service.getInstance().getOrgaizations(0, 0);
        const organization = result.organization.map((element) => ({
          organizationName: element.organizationName,
          name: element.contactName,
          numberOfinvitations: element.numberOfinvitations,
          numberOfRegisteredMentors: element.numberOfRegisteredMentors,
          activeEscortMentors: element.activeEscortMentors,
          role: element.role,
          email: Object(element.user).email,
          phoneNumber: Object(element.user).phoneNumber,
        }));
        const headers = [
          { label: "Organization Name", key: "organizationName" },
          { label: "Name", key: "name" },
          { label: "Number Of Invitations", key: "numberOfinvitations" },
          {
            label: "Number Of Registered Mentors",
            key: "numberOfRegisteredMentors",
          },
          { label: "Active Escort Mentors", key: "activeEscortMentors" },
          { label: "Role", key: "role" },
          { label: "Email", key: "email" },
          { label: "Organization Phone Number", key: "immigrantPhoneNumber" },
        ];
        setHeaders(headers);
        setCSVDataState(organization);
        setFileNameState(CSVFileName.organizations);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Box>
      <Button
        variant="contained"
        disableElevation
        sx={{ bgcolor: globalColors.buttonBg, textTransform:"capitalize" }}
        onClick={() => exportToCSV()}
      >
        <span>{GlobalTitles.Export}</span>
      </Button>
      <CSVLink
        ref={csvLinkRef}
        className="export-btn"
        data={csvDataState}
        headers={headers}
        filename={fileNameState + Date.now().toString() + ".csv"}
        target="_blank"
        hidden={true}
      />
    </Box>
  );
};
