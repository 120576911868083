import { StorageKeys } from "../../commons/enums";
import { LocalStorageService } from "../LocalStorage.service";


export class AuthGuardService {
    private static instance: AuthGuardService;
    private localStorageService = new LocalStorageService();

    static getInstance() {
        if (!this.instance) {
            this.instance = new AuthGuardService();
        }
        return this.instance;
    }

    isUserOTPAuthenticated() {
        const otpToken = this.localStorageService.getToken(
            StorageKeys.OTPToken
        );
        return !!otpToken;
    }

    isUserLoggedIn() {
        const [refreshToken, accessToken] = [
            this.localStorageService.getToken(StorageKeys.RefreshToken),
            this.localStorageService.getToken(StorageKeys.AccessToken),
        ];
        // TODO: we have to improve this
        return !!accessToken;
    }
}
