import React, { useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { AuthUser } from "../../Models";
import { GlobalInputLabels, GlobalMessages } from "../../commons/I18n";
import { FormErrors, GlobalTitles } from "../../commons/enums";
import { LoginForm } from "../../commons/types/LoginFormType";
import { AuthService } from "../../services";
import "./Login.scss";
import { OtpValidation } from "./OtpValidation/OtpValidation";
// import { toast } from "react-toastify";


export const Login = () => {
    const [sendToChild, setSendToChild] = useState({} as LoginForm);
    const [showPhoneValidation,setshowPhoneValidation] = useState(false);
    const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState(
        FormErrors.InvalidLoginMsg
        );
        const [showError, setShowError] = useState(false);
        const [guestEmail, setGuestEmail] = useState("");

    const resolver: Resolver<LoginForm> = async (values: LoginForm) => {
        setErrorMessage(FormErrors.InvalidLoginMsg);
        if (!values.email) {
            return {
                values: values.email ? values : {},
                errors: !values.email
                    ? {
                          email: {
                              type: "required",
                              message: FormErrors.InvalidLoginMsg,
                          },
                      }
                    : {},
            };
        } else if (!values.password) {
            return {
                values: values.password ? values : {},
                errors: !values.password
                    ? {
                          password: {
                              type: "required",
                              message: FormErrors.InvalidLoginMsg,
                          },
                      }
                    : {},
            };
        } else {
            return {
                values: values.email || values.password ? values : {},
                errors:
                    !values.email || !values.password
                        ? {
                              password: {
                                  type: "required",
                                  message: FormErrors.InvalidLoginMsg,
                              },
                          }
                        : {},
            };
        }
    };
    async function togglePopup() {
        if (
            guestEmail !== "" &&
            guestEmail.includes("@") &&
            (guestEmail.includes(".com") || guestEmail.includes("co.il"))
        ) {
            await AuthService.getInstance().forgotPassword(guestEmail);
            setShowResetPasswordPopup(!showResetPasswordPopup);
        } else {
            setShowError(true);
            setErrorMessage(FormErrors.InvalidEmail);
        }
    }
    async function closetogglePopup() {
        if (
            guestEmail !== "" &&
            guestEmail.includes("@") &&
            (guestEmail.includes(".com") || guestEmail.includes("co.il"))
        ) {
            setShowResetPasswordPopup(!showResetPasswordPopup);
        } 
    }

    function getPopUpTemplate() {
        if (showResetPasswordPopup) {
            return (
                <div className="msg-send-popup">
                    <div className="bg-wrapper">
                        <div className="popup-wrapper">
                            <h5>{GlobalMessages.ResetPassword}</h5>
                            <button onClick={closetogglePopup}>X</button>
                        </div>
                    </div>
                </div>
            );
        }
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginForm>({ resolver });
    const onSubmit = async (data: LoginForm) => {
        try {
            const authUser = new AuthUser();
            authUser.email = data.email;
            authUser.password = data.password;
            const isVerfied = await AuthService.getInstance().login(authUser);
            if(isVerfied){
                setshowPhoneValidation(true);
            }else{
                setShowError(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    function getForm() {
        return (
            <div className="login-landingPage-wrapper">
                <div className="login-logo">
                    <img src="../images/global/Aliyapp-Logo.svg" alt="Aliyapp logo" />
                </div>
                <div className="login-form-container">
                    <div className="form-wrapper">
                        <h1 className="login-title">{GlobalTitles.Login}</h1>

                        <form onSubmit={async (e) => handleSubmit(onSubmit)(e)}>
                            <div className="inputs-wrapper">
                                <div className="form-input">
                                    <label htmlFor="email">
                                        {GlobalInputLabels.Email}
                                    </label>
                                    <input
                                        className={
                                            errors?.email
                                                ? "emailInputError"
                                                : ""
                                        }
                                        type="email"
                                        {...register("email")}
                                        placeholder="Example@Aliyapp.co.il"
                                        pattern="[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+(\.[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.([cC][oO][mM]))(:[0-9]{1,5})?"

                                        onKeyUp={(
                                            e: React.KeyboardEvent<HTMLInputElement>
                                        ) => {
                                            setGuestEmail(
                                                (e.target as HTMLInputElement)
                                                    .value
                                            );
                                        }}
                                    />
                                </div>
                                <div className="form-input">
                                    <label htmlFor="password">
                                        {GlobalInputLabels.Password}
                                    </label>
                                    <input
                                        type="password"
                                        className={
                                            showError
                                                ? "passwordInputError"
                                                : ""
                                        }
                                        {...register("password")}
                                        placeholder="******"
                                    />
                                </div>
                                {(errors?.email ||
                                    errors?.password ||
                                    showError) && (
                                    <p className="formError">{errorMessage}</p>
                                )}
                            </div>

                            <div className="form-actions">
                                <button className="submit-form">
                                    {GlobalTitles.Login}
                                </button>
                            </div>
                        </form>
                        <button
                            className="forgot-password"
                            onClick={togglePopup}
                        >
                            {GlobalMessages.ForgotPassword}
                        </button>
                        {showResetPasswordPopup ? getPopUpTemplate() : ""}
                    </div>
                </div>
            </div>
        );
    }

    function getOtpValidation() {
        return <OtpValidation></OtpValidation>;
    }

    if (showPhoneValidation) {
        return getOtpValidation();
    } else {
        return getForm();
    }
};
