import { ContactMail, Scale, ContactPhone } from "@mui/icons-material";
import BarChartSharpIcon from "@mui/icons-material/BarChartSharp";
import BiotechSharpIcon from "@mui/icons-material/BiotechSharp";
import CreditCardSharpIcon from "@mui/icons-material/CreditCardSharp";
import ExitToAppSharpIcon from "@mui/icons-material/ExitToAppSharp";
import PsychologyAltSharpIcon from "@mui/icons-material/PsychologyAltSharp";
import SupervisorAccountSharpIcon from "@mui/icons-material/SupervisorAccountSharp";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HeaderTabs } from "../../commons/enums/HeaderTabs.enum";
import { HeaderTabPanelProps } from "../../commons/types/HeaderTabsType";
import { AuthService } from "../../services";
import { AuthGuardService } from "../../services/guard/AuthGuard.service";
import { Immigrants } from "../Immigrants/Immigrants";
import { Inquiries } from "../Inquiries/Inquiries ";
import { JoinRequests } from "../JoinRequests/JoinRequests ";
import { Mentors } from "../Mentors/Mentors";
import { Organizations } from "../Organizations/Organizations";
import { Statistics } from "../Statistics/Statistics";
import { Surveys } from "../Surveys/Surveys";
import { WeightField } from "../WeightField/WeightField";
import "./Header.scss";
import { LeumitIcon } from "./LeumitLogo/LeumitLogoIcon";

export const Header = () => {
  const navigate = useNavigate();

  const TabPanel = (props: HeaderTabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className: "topHeader-tab",
    };
  };

  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue !== HeaderTabs.LogOut) {
      setValue(newValue);
    }
  };

  const logOutHandler = async () => {
    try {
      const isLoggedIn = AuthGuardService.getInstance().isUserLoggedIn();
      if (isLoggedIn) {
        await AuthService.getInstance().logout();
        return navigate("/", { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Leumit-Header"
          className="topHeader"
          indicatorColor="secondary"
        >
          <Tab icon={<LeumitIcon />} disabled aria-label="Logo" />
          <Tab
            icon={<PsychologyAltSharpIcon />}
            iconPosition="start"
            label="Olim"
            {...a11yProps(HeaderTabs.Immigrants)}
          />
          <Tab
            icon={<BiotechSharpIcon />}
            iconPosition="start"
            label="Mentors"
            {...a11yProps(HeaderTabs.Mentors)}
          />
          <Tab
            icon={<ContactMail />}
            iconPosition="start"
            label="Inquiries"
            {...a11yProps(HeaderTabs.Inquiries)}
          />
          <Tab
            icon={<ContactPhone />}
            iconPosition="start"
            label="Join Requests"
            {...a11yProps(HeaderTabs.JoinRequests)}
          />
          <Tab
            icon={<CreditCardSharpIcon />}
            iconPosition="start"
            label="Surveys"
            {...a11yProps(HeaderTabs.Surveys)}
          />
          <Tab
            icon={<SupervisorAccountSharpIcon />}
            iconPosition="start"
            label="Organizations"
            {...a11yProps(HeaderTabs.Organizations)}
          />
          <Tab
            icon={<BarChartSharpIcon />}
            iconPosition="start"
            label="Statistics"
            {...a11yProps(HeaderTabs.Statistics)}
          />
          <Tab
            icon={<Scale />}
            iconPosition="start"
            label="WeightField"
            {...a11yProps(HeaderTabs.WeightField)}
          />
          <Tab
            icon={<ExitToAppSharpIcon />}
            iconPosition="start"
            label="Log out"
            {...a11yProps(HeaderTabs.LogOut)}
            onClick={logOutHandler}
          />
        </Tabs>
      </Box>

      <Box px={"20px"}>
        <TabPanel value={value} index={1}>
          <Immigrants></Immigrants>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Mentors></Mentors>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Inquiries></Inquiries>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <JoinRequests></JoinRequests>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Surveys></Surveys>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Organizations></Organizations>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <Statistics></Statistics>
        </TabPanel>
        <TabPanel value={value} index={8}>
          <WeightField></WeightField>
        </TabPanel>
        <TabPanel value={value} index={9}></TabPanel>
      </Box>
    </Box>
  );
};