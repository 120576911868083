import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import { SurveysModel } from "../../Models/Surveys.model";
import { SurveysTableHeader } from "../../commons/I18n";
import { Routes } from "../../commons/enums";
import { SurveysTable } from "../../commons/enums/GlobalTableHeaders.enum";
import { GlobalTitles } from "../../commons/enums/Titles.enum";
import {
  SurveysResponse,
  SurveysTableType,
} from "../../commons/types/SurveysTypes";
import { globalColors } from "../../globalColors";
import { Service } from "../../services/service.service";
import { Export } from "../Export/Export";
import "./Surveys.scss";
import { LightTooltip } from "../LightTooltip/LightTooltip";

export const Surveys = () => {
  const [page, setPage] = useState(0);
  const [limit] = useState(10);
  const [total, setTotalInquiries] = useState(0);
  const [surveys, setSurveys] = useState([] as SurveysModel[]);
  const [date, setDate] = useState([]);
  const { RangePicker } = DatePicker;

  // Table header
  const headCells: readonly SurveysTableType[] = [
    {
      id: SurveysTable.Permission,
      numeric: true,
      disablePadding: false,
      label: SurveysTableHeader.Permission,
    },
    {
      id: SurveysTable.FullName,
      numeric: true,
      disablePadding: false,
      label: SurveysTableHeader.FullName,
    },
    {
      id: SurveysTable.HisMatch,
      numeric: true,
      disablePadding: false,
      label: SurveysTableHeader.HisMatch,
    },
    {
      id: SurveysTable.SurveyAnswer,
      numeric: true,
      disablePadding: false,
      label: SurveysTableHeader.SurveyAnswer,
    },
    {
      id: SurveysTable.Date,
      numeric: true,
      disablePadding: false,
      label: SurveysTable.Date,
    },
  ];

  useEffect(() => {
    getSurveys([], date);
  }, [page, limit]);

  const getSurveys = async (value: any, dateString: any) => {
    const result: SurveysResponse = await Service.getInstance().getSurveys(
      dateString,
      page + 1,
      limit
    );
    setDate(dateString);
    setSurveys(result.surveys);
    setTotalInquiries(result.total);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isEvenOrOdd = (index: number) => {
    return index % 2 !== 0;
  };

  return (
    <div className="immigrants-wrapper">
      <Box>
        <Box>
          <TableContainer>
            <Table aria-labelledby="tableTitle" size="small"
                            sx={{ tableLayout: "fixed" }}

            >
              <TableHead>
                <TableRow>
                  <TableCell padding="none" align="center" colSpan={5}>
                    <Typography
                      variant="h5"
                      my={3}
                      textAlign={"left"}
                      className="page-title"
                    >
                      {GlobalTitles.Surveys}
                    </Typography>
                    <Box
                      my={2}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <RangePicker
                        allowClear={false}
                        id="date"
                        name="date"
                        size="large"
                        style={{ width: "400px" }}
                        onChange={getSurveys}
                      />
                      <Export exportRequest={Routes.getSurveys} />
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow
                  className="table-headers"
                  sx={{ bgcolor: globalColors.tableHeadBg }}
                >
                  {headCells.map((headCell) => (
                    <LightTooltip title={headCell.label} placement="top">
                      <TableCell
                        key={headCell.id}
                        align={"center"}
                        padding={"normal"}
                        sortDirection={false}
                        sx={{fontWeight:"bold"}}
                      >
                        {headCell.label}
                      </TableCell>
                    </LightTooltip>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {surveys?.map((row, index) => {
                  const tableRowStyle = "researcher-row ";
                  const tableRowBlueStyle = "researcher-row table-row-bg-blue";
                  const displayRow = (
                    <TableRow
                      className={
                        isEvenOrOdd(index) ? tableRowBlueStyle : tableRowStyle
                      }
                    >
                      <TableCell align="center">
                        {Object(row.permission)}
                      </TableCell>
                      <TableCell align="center">
                        {Object(row.immigrantId).name ??
                          Object(row.mentorId).name}
                      </TableCell>
                      <TableCell align="center">
                        {Object(row.immigrantId)?.mentor?.name ??
                          Object(row.mentorId)?.immigrants?.name}
                      </TableCell>
                      <TableCell align="center">
                        {Object(row.surveyAnswer)}
                      </TableCell>
                      <TableCell align="center">
                        {new Date(Object(row.date)).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  );
                  return displayRow;
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Box justifyContent={"flex-end"} display={"flex"}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              className="pagination"
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};
