import { UploadOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import { Form, Modal, Select, Upload } from "antd";
import { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { GlobalTitles } from "../../../commons/enums";
import { globalColors } from "../../../globalColors";
import { Service } from "../../../services/service.service";
import "../../Style/CreateStyle.scss";

const { Option } = Select;

export const CreatMultiImmigrants = () => {
  const CollectionCreateForm = ({
    visible,
    onCreate,
    onCancel,
    showError,
    response,
  }: {
    visible: any;
    response: string;
    showError: boolean;
    onCreate: any;
    onCancel: any;
  }) => {
    const [form] = Form.useForm();

    return (
      <Modal
        visible={visible}
        title="Upload list of immigrants."
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="systemLanguage"
            label="System Language"
            style={{
              padding: "10px",
              width: "320px",
            }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <Option key="english_us" value="english_us">
                English (US)
              </Option>
              <Option key="english_uk" value="english_uk">
                English (UK)
              </Option>
              <Option key="french" value="french">
                French
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="file"
            label="File"
            style={{
              padding: "10px",
              width: "320px",
            }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Upload name="file" listType="text" beforeUpload={() => false}>
              <Button startIcon={<UploadOutlined />} variant="outlined">
                Click to upload
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const CollectionsPage = () => {
    const [visible, setVisible] = useState(false);
    const [response, setResponse] = useState("");
    const [showError, setShowError] = useState(false);

    const onCreate = async (values: any) => {
      const formData = new FormData();
      formData.append("file", values.file.file);
      formData.append("systemLanguage", values.systemLanguage);
      await Service.getInstance().createMultiImmigrants(formData);
      setVisible(false);
    };

    return (
      <div>
        <Button
          variant="contained"
          disableElevation
          sx={{ textTransform: "capitalize", bgcolor: globalColors.buttonBg }}
          onClick={() => {
            setVisible(true);
            setShowError(false);
          }}
        >
          <span>{GlobalTitles.CreateImmigrants}</span>
        </Button>
        <CollectionCreateForm
          visible={visible}
          onCreate={onCreate}
          showError={showError}
          response={response}
          onCancel={() => {
            setVisible(false);
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <CollectionsPage />
    </div>
  );
};
