export enum GlobalErrors {
    GeneralError = "שגיאה כללית",
    DatabaseError = "שגיאת מערכת, נא לנסות שוב או לפנות תמיכה",
    EmptyValues = "נא להזין את כל שדות החובה",
}
export enum GlobalMessages {
    ResetPassword = "An email with a password has been sent to your email address",
    ForgotPassword = "Forgot my password",
    SendCode = "Send Code",
    SendOtpAgain = "Did not receive an SMS, please send again",
    ChangeNumber = "Change phone number",
}
export enum GlobalInputLabels {
    Login = "Login",
    Email = "Email",
    Password = "Password",
    Phone = "Phone",
    Otp = "Otp",
}

export enum ImmigrantsTableHeader {
   FullName = "Full Name",
   Email = "Email",
   Phone = "Contact Phone",
   Country = "Country",
   Language = "Language",
   RegistrationDate = "Registration Date",
   RouteType = "Route Type",
   Professional = "Professional",
   MentorName = "Mentor Name",
   MentorPhone = "Mentor Phone",
}

export enum MentorsTableHeader {
    FullName = "Full Name",
    Email = "Email",
    Phone = "Phone",
    RegistrationDate = "Registration Date",
    City = "City",
    Profession = "Profession",
    RouteType = "Route Type",
    Language = "Language",
    Organization = "Organization",
    ImmigrantName = "Immigrant Name",
    ImmigrantPhone = "Immigrant Phone",
    HasConnection = "Has Connection",
    Action = "Action",
}

export enum InquriesTableHeader {
    Header = "Header",
    Email = "Email",
    Date = "Date",
    Content = "Content",
    Action = "Action",
}

export enum JoinRequestsTableHeader {
    FullName = "Full Name",
    PhoneNumber = "Phone Number",
    Date = "Date",
    DesiredPermission = "Desired Permission",
    Action = "Action",
    HeardFrom = "Heard From",
    Country = "Country",
    Email = "Email",
}

export enum SurveysTableHeader {
    Permission = "Permission",
    FullName =  "Full Name",
    HisMatch = "His Match", 
    SurveyAnswer = "Survey Answer",
}

export enum WeightFieldTableHeader {
    Id =  "Id",
    FieldName =  "Field Name",
    Weight = "Weight", 
    Type = "Type",
    Action = "Action",
}

export enum OrganizationsTableHeader {
        OrganizationName = "Organization Name",
        ContactName = "Contact Name",
        NumberOfinvitations = "Number Of Invitations",
        NumberOfRegisteredMentors = "Number Of Registered Mentors",
        ActiveEscortMentors = "Active escort mentors",
        Role = "Role",
        Email = "Email",
        Phone = "Phone",
        Action = "Action",
}
