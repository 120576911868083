import GlobalApiService from "../services/api/GlobalApi.service";
import * as ApiInterfaces from "../commons/interfaces/Api.interfaces";
import { ImmigrantResponse } from "../commons/types/ImmigrantsTypes";
import { Routes,RequestEnum, StorageKeys } from "../commons/enums";
import { AuthService } from "./api/AuthService.service";
import {environment} from "../environments/Environment"
import axios from "axios";
import { InquiriesResponse } from "../commons/types/InquiriesTypes";
import { MentorsResponse } from "../commons/types/MentorsTypes";
import { OrganizationsResponse } from "../commons/types/OrganizationsType";
import { StatisticsResponse } from "../commons/types/StatisticsTypes";
import { SurveysResponse } from "../commons/types/SurveysTypes";
import { WeightFieldResponse } from "../commons/types/WeightFieldTypes";
import { JoinRequestsResponse } from "../commons/types/JoinRequestsTypes";
// import {JoinRequest} from '../commons/types/'

export class Service {
    private static instance: Service;
    static getInstance() {
        if (!this.instance) {
            this.instance = new Service();
        }
        return this.instance;
    }

    async getImmigrants(page:number,limit:number): Promise<ImmigrantResponse> {
        const immigrantResponse:
            | ImmigrantResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getImmigrants,
                {page,limit}
            );
        let res = {} as ImmigrantResponse;
        if (
            typeof immigrantResponse === "object" &&
            "immigrants" in immigrantResponse
        ) {
            res = immigrantResponse;
        } 
        return res;
    }

    async getMentors(page:number,limit:number): Promise<MentorsResponse> {
        const mentorsResponse:
            | MentorsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getMentors,
                {page,limit}
            );
        let res = {} as MentorsResponse;
        if (
            typeof mentorsResponse === "object" &&
            "mentors" in mentorsResponse
        ) {
            res = mentorsResponse;
        } 
        return res;
    }
  
    async getSurveys(query:any,page:number,limit:number): Promise<SurveysResponse> {
        const surveysResponse:
            | SurveysResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getSurveys,
                {query,page,limit}
            );
        let res = {} as SurveysResponse;
        if (
            typeof surveysResponse === "object" &&
            "surveys" in surveysResponse
        ) {
            res = surveysResponse;
        } 
        return res;
    }
 
    async getWeightField(): Promise<WeightFieldResponse> {
        const weightFieldResponse:
            | WeightFieldResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getWeightField,
                {}
            );
        let res = {} as WeightFieldResponse;
        if (
            typeof weightFieldResponse === "object" &&
            "WeightField" in weightFieldResponse
        ) {
            res = weightFieldResponse;
        } 
        return res;
    }
 
    async getInquiries(query:any,page:number,limit:number): Promise<InquiriesResponse> {
        const inquiriesResponse:
            | InquiriesResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getInquiries,
                {query,page,limit}
            );
        let res = {} as InquiriesResponse;
        if (
            typeof inquiriesResponse === "object" &&
            "inquiries" in inquiriesResponse
        ) {
            res = inquiriesResponse;
        } 
        return res;
    }

    async getJoinRequests(query:any,page:number,limit:number): Promise<JoinRequestsResponse> {
        const joinRequestsResponse:
            | JoinRequestsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getJoinRequests,
                {query,page,limit}
            );
        let res = {} as JoinRequestsResponse;
        if (
            typeof joinRequestsResponse === "object" &&
            "joinRequests" in joinRequestsResponse
        ) {
            res = joinRequestsResponse;
        } 
        return res;
    }

    async getOrgaizations(page:number,limit:number): Promise<OrganizationsResponse> {
        const organizationResponse:
            | OrganizationsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getOrganizations,
                {page,limit}
            );
        let res = {} as OrganizationsResponse;
        if (
            typeof organizationResponse === "object" &&
            "organization" in organizationResponse
        ) {
            res = organizationResponse;
        } 
        return res;
    }
    
    async delete(id: string,routes:Routes){
            await GlobalApiService.generalRequest(
                RequestEnum.Delete,
                `${routes}/${id}`,
                []
            );
    }

    async update(id: string,type:string,weight:number,routes:Routes){
            await GlobalApiService.generalRequest(
                RequestEnum.Put,
                `${routes}/${id}`,
                {
                    weight,
                    type
                }
            );
    }
    
    async updateOrganiztionName(id: string,organizationName: string,routes:Routes){
            await GlobalApiService.generalRequest(
                RequestEnum.Put,
                `${routes}/${id}`,
                {
                    organizationName
                }
            );
    }

    async updateMentorName(id: string,name: string,routes:Routes){
            await GlobalApiService.generalRequest(
                RequestEnum.Put,
                `${routes}/${id}`,
                {
                    name
                }
            );
    }

    async createOrganization(
        Organization: object
    ): Promise<OrganizationsResponse | string| ApiInterfaces.ErrorResponse > {
        const postResponse:
            | OrganizationsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Post,
                Routes.POST_ORGANUZATUIN,
                Organization
            );
            if (
                typeof postResponse === "object" &&
                "message" in postResponse
            ) {
                return postResponse.message;
            }
            return postResponse;
    }

    async createMultiImmigrants(
        Immigrants: object
    ): Promise<ImmigrantResponse | string| ApiInterfaces.ErrorResponse > {
        const postResponse:
            | ImmigrantResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Post,
                Routes.postCsvImmigrants,
                Immigrants
            );
            if (
                typeof postResponse === "object" &&
                "statusCode" in postResponse
            ) {
                return postResponse.message;
            }
            return postResponse;
    }

    async createImmigrants(
        Immigrants: object
    ): Promise<ImmigrantResponse | string| ApiInterfaces.ErrorResponse > {
        const postResponse:
            | ImmigrantResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Post,
                Routes.postRegister,
                Immigrants
            );
            if (
                typeof postResponse === "object" &&
                "statusCode" in postResponse
            ) {
                return postResponse.message;
            }
            return postResponse;
    }
 
    async exportResearchersToCSV(
        exportRequest: Routes
    ): Promise<ImmigrantResponse> {
        const response = await axios.request<ImmigrantResponse>({
            method: RequestEnum.Get,
            url: `${environment.API_URL}${exportRequest}`,
            headers: AuthService.getInstance().getByHeaderToken(
                StorageKeys.AccessToken
            ),
        });
        return response.data;
    }

    async getMentorByCity(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getMentorByCity,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }
   
    async getMentorByRegion(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getMentorByRegion,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }

    async getMentorWithoutImmigrant(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getMentorWithoutImmigrant,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }

    async numOfMatching(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.numOfMatching,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }
    
    async getMentorByOrganization(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getMentorByOrganization,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }

    async getMentorByRouteType(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getMentorByRouteType,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }

    async getImmigrantByCountry(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getImmigrantByCountry,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }

    async getImmigrantByRouteType(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getImmigrantByRouteType,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }
 
    async getMentorPerImmigrant(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getMentorPerImmigrant,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }
    
    async immigrantByProfessional(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getimmigrantByProfessional,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }

    async mentorByProfessional(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getmentorByProfessional,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }

    async immigrantByMedical(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getimmigrantByMedical,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }

    async mentorByMedical(): Promise<StatisticsResponse> {
        const pResearchersResponse:
            | StatisticsResponse
            | string
            | ApiInterfaces.ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.getmentorByMedical,
                {}
            );
        let res = {} as StatisticsResponse;
        if (
            typeof pResearchersResponse === "object" &&
            "result" in pResearchersResponse
        ) {
            res = pResearchersResponse;
        } 
        return res;
    }


}
