import { Circle } from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { PieModel } from "../../Models/Statistics.model";
import { pieColors } from "../../globalColors";
import { LightTooltip } from "../LightTooltip/LightTooltip";

export const HalfPie = ({ data }: { data: PieModel[] }) => {
  const filtered = data
    .filter((item) => item.label?.length > 2)
    .filter((item) => Number(item.value) > 0);
  filtered.forEach((item) => {
    if (Array.isArray(item.label)) item.label = item.label.sort().join(", ");
    if (Array.isArray(item.value)) item.value = item.value.sort().join(", ");
  });
  const formatted = filtered.map((item, index) => {
    return {
      id: item.id,
      label: item.label,
      value: Number(item.value),
      color: pieColors[index % pieColors.length],
    };
  });
  const sum = formatted.reduce((a, b) => a + b.value, 0);

  const CustomTooltip = (props: any) => {
    const index = props.itemData.dataIndex ?? 0;
    const percent = Math.round((props.series.data[index].value / sum) * 100);
    const angle =
      props.series.data[index].endAngle - props.series.data[index].startAngle;
    if (angle < 0.4)
      return (
        <Box margin={1}>
          <Chip
            sx={{
              bgcolor: "#ffffff97",
              position: "relative",
              top: -40,
            }}
            label={percent + "%"}
            variant="outlined"
          />
        </Box>
      );
    else return <></>;
  };
  return (
    <>
      {data.length > 0 ? (
        <Box mx={1}>
          <PieChart
            colors={pieColors}
            series={[
              {
                startAngle: -90,
                endAngle: 90,
                data: formatted,
                innerRadius: 70,
                outerRadius: 120,
                arcLabel: (item) => Math.floor((item.value / sum) * 100) + "%",
                arcLabelMinAngle: 15,
              },
            ]}
            width={450}
            height={150}
            margin={{
              left: 0,
              right: 0,
              top: 100,
              bottom: 0,
            }}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
              },
            }}
            slots={{
              itemContent: CustomTooltip,
            }}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
          />
          <Box>
            <Box
              display="flex"
              flexDirection={"row"}
              flexWrap={"wrap"}
              justifyContent={"center"}
              height={"140px"}
              overflow="auto"
              sx={{ direction: "rtl", overflowX: "hidden" }}
            >
              {formatted.map((item) => {
                return (
                  <ListItem sx={{ padding: 0, width: "calc(100% / 3.1)" }}>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <Circle sx={{ color: item.color, fontSize: "15px" }} />
                    </ListItemIcon>
                    <LightTooltip title={item.label} placement="top">
                      <ListItemText
                        sx={{
                          textWrap: "nowrap",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                        primary={item.label}
                        disableTypography
                      />
                    </LightTooltip>
                  </ListItem>
                );
              })}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height={"80%"}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};
