import { useEffect, useState } from "react";
// import "./Organizations.scss";
import { Close, Delete, ModeEditOutline } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { OrganizationsModel } from "../../Models/Organizations.model";
import { OrganizationsTableHeader } from "../../commons/I18n";
import { Routes } from "../../commons/enums";
import { OrganizationsTable } from "../../commons/enums/GlobalTableHeaders.enum";
import { GlobalTitles } from "../../commons/enums/Titles.enum";
import {
  OrganizationsResponse,
  OrganizationsTableType,
} from "../../commons/types/OrganizationsType";
import { globalColors } from "../../globalColors";
import { ConfirmBox } from "../../model/ConfirmBox";
import { Service } from "../../services/service.service";
import { Export } from "../Export/Export";
import { LightTooltip } from "../LightTooltip/LightTooltip";
import Search from "../Search/Search";
import { CreateOrganization } from "./CreateOrganization/CreateOrganization";

export const Organizations = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [organizations, setOrganizations] = useState(
    [] as OrganizationsModel[]
  );
  const [total, setTotalOrganizations] = useState(0);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setopenEdit] = useState(false);
  const [id, setID] = useState("");
  const [name, setName] = useState("");
  const [hasCreatedNew, setCreatedNew]= useState(false)

  // Table header
  const headCells: readonly OrganizationsTableType[] = [
    {
      id: OrganizationsTable.OrganizationName,
      numeric: true,
      disablePadding: false,
      label: OrganizationsTableHeader.OrganizationName,
    },
    {
      id: OrganizationsTable.ContactName,
      numeric: true,
      disablePadding: false,
      label: OrganizationsTableHeader.ContactName,
    },
    {
      id: OrganizationsTable.NumberOfinvitations,
      numeric: true,
      disablePadding: false,
      label: OrganizationsTableHeader.NumberOfinvitations,
    },
    {
      id: OrganizationsTable.NumberOfRegisteredMentors,
      numeric: true,
      disablePadding: false,
      label: OrganizationsTableHeader.NumberOfRegisteredMentors,
    },
    {
      id: OrganizationsTable.ActiveEscortMentors,
      numeric: true,
      disablePadding: false,
      label: OrganizationsTableHeader.ActiveEscortMentors,
    },
    {
      id: OrganizationsTable.Role,
      numeric: true,
      disablePadding: false,
      label: OrganizationsTableHeader.Role,
    },
    {
      id: OrganizationsTable.Email,
      numeric: true,
      disablePadding: false,
      label: OrganizationsTableHeader.Email,
    },
    {
      id: OrganizationsTable.Phone,
      numeric: true,
      disablePadding: false,
      label: OrganizationsTableHeader.Phone,
    },
    {
      id: OrganizationsTable.Action,
      numeric: true,
      disablePadding: false,
      label: OrganizationsTableHeader.Action,
    },
  ];

  const handleGetOrginizations = async () => {
    try {
      const result = await getOrganizations();
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (hasCreatedNew) {
      handleGetOrginizations()
      setCreatedNew(false);
    }


  }, [hasCreatedNew])

  useEffect(() => {
    setOrganizations(organizations);
  }, [organizations]);

  useEffect(() => {
    getOrganizations();
    console.log(1);
  }, [page, limit, total]);

  const getOrganizations = async () => {
    const result: OrganizationsResponse =
      await Service.getInstance().getOrgaizations(page + 1, limit);
    setOrganizations(result.organization);
    setTotalOrganizations(result.total);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const data = {
    nodes: organizations.filter((item) => {
      return (
        item.organizationName
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1
      );
    }),
  };

  const deleteId = async (id: string) => {
    setOpen(false);
    try {
      await Service.getInstance().delete(id, Routes.deleteOrganizations);
    } catch (error) {
      setTotalOrganizations(total - 1);
    }
  };

  const editFunction = async (id: string) => {
    setopenEdit(false);
    await Service.getInstance().updateOrganiztionName(
      id,
      name,
      Routes.updateOrganizationsField
    );
    getOrganizations();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isEvenOrOdd = (index: number) => {
    return index % 2 !== 0;
  };

  return (
    <>
      <div className="immigrants-wrapper">
        <Box>
          <Box>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                sx={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="none" align="center" colSpan={9}>
                      <Box>
                        <Typography
                          variant="h5"
                          my={3}
                          textAlign={"left"}
                          className=" page-title"
                        >
                          {GlobalTitles.Organizations}
                        </Typography>
                        <Box
                          mb={2}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Search handleSearch={handleSearch} />
                          <Export exportRequest={Routes.getOrganizations} />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    className="table-headers"
                    sx={{ bgcolor: globalColors.tableHeadBg }}
                  >
                    {headCells.map((headCell) => (
                      <LightTooltip title={headCell.label} placement="top">
                        <TableCell
                          key={headCell.id}
                          align={"center"}
                          padding={"normal"}
                          sortDirection={false}
                          sx={{ fontWeight: "bold" }}
                        >
                          {headCell.label}
                        </TableCell>
                      </LightTooltip>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data["nodes"].map((row, index) => {
                    const tableRowStyle = "researcher-row ";
                    const tableRowBlueStyle =
                      "researcher-row table-row-bg-blue";
                    const displayRow = (
                      <TableRow
                        className={
                          isEvenOrOdd(index) ? tableRowBlueStyle : tableRowStyle
                        }
                      >
                        <TableCell align="center">
                          {row.organizationName}
                        </TableCell>
                        <TableCell align="center">{row.contactName}</TableCell>
                        <TableCell align="center">
                          {row.numberOfinvitations}
                        </TableCell>
                        <TableCell align="center">
                          {row.numberOfRegisteredMentors}
                        </TableCell>
                        <TableCell align="center">
                          {row.activeEscortMentors}
                        </TableCell>
                        <TableCell align="center">{row.role}</TableCell>
                        <TableCell align="center">
                          {Object(row.user).email}
                        </TableCell>
                        <TableCell align="center">
                          {Object(row.user).phoneNumber}
                        </TableCell>
                        <TableCell align="center">
                          <Box display={"flex"} justifyContent={"space-evenly"}>
                            <IconButton
                              onClick={() => {
                                setopenEdit(true);
                                setID(Object(row._id));
                              }}
                            >
                              <ModeEditOutline />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setID(Object(row.user)._id);
                                setName(row.organizationName);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                    return displayRow;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box justifyContent={"flex-end"} display={"flex"}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                className="pagination"
              />
            </Box>
          </Box>
        </Box>
      </div>
      <ConfirmBox
        open={open}
        closeDialog={() => setOpen(false)}
        title={name}
        deleteFunction={() => deleteId(id)}
      ></ConfirmBox>
      <Dialog
        fullWidth
        open={openEdit}
        maxWidth="md"
        scroll="body"
        onClose={() => setopenEdit(false)}
        onBackdropClick={() => setopenEdit(false)}
      >
        <DialogContent sx={{ px: 8, py: 6, position: "relative" }}>
          <IconButton
            size="medium"
            onClick={() => setopenEdit(false)}
            sx={{ position: "absolute", right: "1rem", top: "1rem" }}
          >
            <Close />
          </IconButton>

          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box
                sx={{
                  mb: 3,
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h5">Edit Name</Typography>
                <br />
                <Typography variant="body1">
                  Are you sure you want to edit this name?
                  <input
                    style={{ textAlign: "center" }}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </Typography>
                <></>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
            >
              <Button
                style={{ backgroundColor: "#5AE1EC" }}
                onClick={() => setopenEdit(false)}
                size="medium"
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                onClick={() => editFunction(id)}
                size="medium"
                variant="contained"
                color="error"
              >
                Edit
              </Button>{" "}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <CreateOrganization setCreatedNew={setCreatedNew}  />
    </>
  );
};
