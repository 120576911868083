export enum HeaderTabs {
    Immigrants = 1,
    Mentors = 2,
    Inquiries = 3,
    JoinRequests = 4,
    Surveys = 5,
    Organizations = 6,
    Statistics = 7,
    WeightField = 8,
    LogOut = 9,
}
