export enum RequestEnum {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE',
    Bearer = 'Bearer',
    String = "string",
    XInitializedAt = 'X-Initialized-At',
    ErrorOnReq = 'Error on route request',
    ErrorConcat = ', error: ',
    ErrorBadRequest = 'Bad request',
}

export enum ResponseEnum {
    Undefined = 'undefined',
    Unauthorized = 'Unauthorized',
    Forbidden = 'forbidden',
    UnknownError = 'UnknownError',
    UnknownErrorMessage = 'An unexpected error occurred.',
}

