import { Box, Paper, Stack, Typography } from "@mui/material";
import { globalColors } from "../../globalColors";

export default function NumberStat({
  number,
  text,
}: {
  number: number;
  text: string;
}) {
  return (
    <Paper
      elevation={1}
      sx={{ width: "466px", height: "170px", borderRadius: "15px"}}
    >
      <Stack
        width={"100%"}
        height="100%"
        direction="row"
        alignItems={"center"}
        spacing={"25px"}
        paddingX={2}
        boxSizing={"border-box"}
      >
        <Box
          sx={{
            minWidth: 115,
            minHeight: 115,
            bgcolor: globalColors.statBadge,
            borderRadius: "60%",
          }}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
        >
          <Typography
            fontSize={"24px"}
            color={globalColors.statText}
            fontWeight={"bold"}
            letterSpacing={"-1px"}
          >
            {number}
          </Typography>
        </Box>
        <Box>
          <Typography
            fontSize={"24px"}
            color={globalColors.statText}
            letterSpacing={"-1px"}
            variant={"body1"}
            textAlign={"left"}
            lineHeight={'30px'}
          >
            {text}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
}
